import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    subHours,
    isBefore,
    startOfDay,
    parse,
    format,
    isAfter,
    addDays,
} from 'date-fns';
import { Formik, Form, FormikErrors, FormikValues } from 'formik';
import { Button } from './styled';
import { colors } from '../../../styles/colors';
import {
    Container,
    // PageHeader,
    PageTitle,
    PageSubTitle,
    Content,
} from './styled';
import pin from '../../../assets/pin.svg';
import { Breakpoints } from '../../../dictionaries';
import { config } from '../../../config';
import { AnyObject, Appointment, Location } from '../models';

import ChangeLocationModal from './ChangeLocationModal';
import Slots from './Slots';
import NavigationButtons from './NavigationButtons';
import Datepicker, { DatepickerField } from './Datepicker';
import FormLabel from './form/FormLabel';
import Checkbox from './form/Checkbox';
import { cloneDeep, flatMap, includes, isEqual } from 'lodash';
import { useStepsDispatch, useStepsState, } from '../../shared/providers/schedule/provider';
import { useSharedDispatch, useSharedState, } from '../provider';
import { useManageDispatch, useManageState } from '../../manage/provider';
import LocationModal from '../../manage/components/LocationModal';
import { useAdminDispatch, } from './../../shared/providers/admin/provider';
import RapidAlertModal from './RapidAlertModal';
import { ManagePage } from "../../manage/dictionaries";
import { convertPricing } from '../../../utils';
import { getNpDestionation, getEmployeeCoupon, getCoupon } from '../api'
import Inputs from './form/Input';

const ChangeLocationButton = styled.button`
  display: inline-block;
  border: 0;
  background: none;
  color: ${colors.renderBlue};
  cursor: pointer;
`;

const PinIcon = styled.div`
  position: absolute;
  left: 0;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  margin: -2px 5px 0 0;
  width: 14px;
  height: 20px;
  background: url(${pin}) no-repeat;
  background-size: cover;
`;

const HeaderLocation = styled.div`
  position: relative;
  padding-left: 24px;
  text-align: left;

  @media (min-width: ${Breakpoints.sm}) {
    flex-direction: row;
  }
`;

const PageContent = styled.div`
  border-top: 1px solid ${colors.renderBlue};
  padding-top: 25px;

  @media (min-width: ${Breakpoints.lg}px) {
    padding-top: 45px;
  }
`;

const AboveColumns = styled.div`
  max-width: 650px;
  margin-bottom: 20px;
`;

const Columns = styled.div`
  display: flex;
  margin: 0 -30px;

  & > * {
    margin: 0 30px;
  }
`;

const DatepickerWrapper = styled.div`
  display: none;

  @media (min-width: ${Breakpoints.md}px) {
    display: block;
    flex: 1 1 auto;
  }
`;

const SlotsWrapper = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    flex: 2 1 auto;
  }
`;

const LocationNameAndAddress = styled.div`
  margin-right: 10px;
`;

const PageHeader = styled.div`
  margin-bottom: 40px;
  flex: auto;
`;

const HeadarContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const TestTypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 117px;
    width: 550px;
    margin: 10px;
    justify-content: center;
    text-align: center;
    align-content: center;
`;
const Error = styled.div`
  margin-top: 15px;
`;
const InnerBox = styled.div`
    border: 1px solid rgb(26, 150, 219);
    margin: 0px 10px;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    align-items: center;
    display: grid;
    background: ${({ selected }: { selected?: boolean }) => selected ? colors.blue : colors.white};
`;

const TestTypeTitle = styled.p`
    font-size: medium;
    font-weight: bold;
    color: ${({ selected }: { selected?: boolean }) => selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
      font-size: 15px;
      font-weight: bold;
      color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};
    }
`;
const CouponContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`;

const TestCostLabel = styled.p`
    font-size: 40px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
      font-size: 30px;
      font-weight: bold;
      color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};
    }
`;

const TestDurationInfoLabel = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) => selected ? colors.white : colors.blue};

    @media (max-width: ${Breakpoints.sm}px) {
      font-size: 9px;
      font-weight: bold;
      color: ${({ selected }: { selected?: boolean }) => selected ? colors.white : colors.blue};
    }
`;

const RefundInfoText = styled.div`
    font-weight: bold;
`



interface Props {
    title?: string;
    onSchedule: (values: FormikValues) => void;
    onFieldUpdate: (update: AnyObject) => void;
    onLocationChange?: () => void;
    appointment: Appointment;
    confirmBtnText?: string;
    loading?: boolean;
    isManage?: boolean;
    isAdmin?: boolean;
    prices?: any;
    standardTest?: any;
    RescheduleTestType?: any;
    setIsEmployeeEmail?: (data: boolean) => void;
    isEmployeeEmail?: boolean;
}

export function getMinAvailableDate(
    location: Location | null,
    isExpressSameDayTest: boolean,
    departureDateAndTime: Date,
    isRapidTest: boolean,
): Date {

    let getTimeZoneOffSet = new Date(departureDateAndTime).getTimezoneOffset();
    if (isExpressSameDayTest && location && location.hasVipSlots) {

        if (
            isBefore(
                departureDateAndTime,
                parse(config.schedule.endTime, 'h:mmaaaaa', departureDateAndTime)
            )
        ) {

            return subHours(departureDateAndTime, config.vipSlots.maxAdvanceHours);  //departureDateAndTime;
        }


    }

    if (isRapidTest && location && location.rapidTest) {

        if (
            isBefore(
                departureDateAndTime,
                parse(config.schedule.endTime, 'h:mmaaaaa', departureDateAndTime)
            )
        ) {

            return subHours(departureDateAndTime, config.vipSlots.maxAdvanceHours);  //departureDateAndTime;
        }


    }

    if (
        isBefore(
            subHours(startOfDay(departureDateAndTime), getTimeZoneOffSet == 420 || getTimeZoneOffSet == 300 || getTimeZoneOffSet == 240 ? 71 : config.maxAdvanceHours),
            new Date()
        )
    ) {
        return new Date();
    }

    // avoid to open up previous day without available slots in datepicker
    if (
        isAfter(
            departureDateAndTime,
            parse(config.schedule.endTime, 'h:mmaaaaa', departureDateAndTime)
        )
    ) {
        return subHours(departureDateAndTime, config.minAdvanceHours);
    }

    return subHours(departureDateAndTime, getTimeZoneOffSet == 420 || getTimeZoneOffSet == 300 || getTimeZoneOffSet == 240 ? 71 : config.maxAdvanceHours);
}

export function getMaxAvailableDate(
    location: Location | null,
    isExpressSameDayTest: boolean,
    departureDateAndTime: Date,
    isRapidTest: boolean,
): Date {

    let getTimeZoneOffSet = new Date(departureDateAndTime).getTimezoneOffset();
    if (isExpressSameDayTest && location && location.hasVipSlots) {
        if (
            isBefore(
                departureDateAndTime,
                parse(config.schedule.startTime, 'h:mmaaaaa', departureDateAndTime)
            )
        ) {


        }

        const overTime = new Date(departureDateAndTime).getHours();
        return subHours(departureDateAndTime, overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours);
    }

    if (isRapidTest && location && location.rapidTest) {

        // console.log("getMinAvailableDate", subHours(departureDateAndTime, 3));
        const overTime = new Date(departureDateAndTime).getHours();

        return subHours(departureDateAndTime, 3);
    }

    // avoid to open up next day without available slots in datepicker
    if (
        isBefore(
            departureDateAndTime,
            parse(config.schedule.startTime, 'h:mmaaaaa', departureDateAndTime)
        )
    ) {
        return subHours(departureDateAndTime, getTimeZoneOffSet == 420 || getTimeZoneOffSet == 300 || getTimeZoneOffSet == 240 ? 71 : config.maxAdvanceHours);
    }

    return subHours(departureDateAndTime, config.minAdvanceHours);
}

export function disableFutureDt(): Date {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const disableFuture = new Date(year + 1, month, day); // PLUS 1 YEAR
    return disableFuture;
}

const Schedule: React.FC<Props> = ({
    title,
    appointment,
    onSchedule,
    onFieldUpdate,
    onLocationChange,
    confirmBtnText,
    loading,
    isManage,
    isAdmin,
    prices,
    standardTest,
    RescheduleTestType,
    setIsEmployeeEmail,
    isEmployeeEmail
}) => {
    const {
        location,
        slot,
        departureDateAndTime,
        isExpressSameDayTest,
        date,
        minors,
        isRapidTest,
        travelType,
        destination,
        testSelection,
        isAntigen,
        email,
        promoCode,
        discountAmount,
        hasInsurance
    } = appointment;


    const [btnloading, setBtnLoading] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const [isEmpEmail, setIsEmpEmail] = useState(false);
    const { updateStandradTest, goToPage } = useManageDispatch();   
    const { upateTestType } = useSharedDispatch();
    const [showChangeDatetimeModal, setShowChangeDatetimeModal] = useState(false);
    const [npDestinations, setNpDestinations] = useState([]);

    const getNpDestinations = async () => {
      await getNpDestionation()
        .then((res: any) => {
          if (res?.data?.status === 200) {
            let npValues: any = [];
            res?.data?.data.map((np: any) => {
              npValues.push(np.value);
            });
            setNpDestinations(npValues);
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    useEffect(() => {
        getNpDestinations();
        updateStandradTest(isRapidTest ? false : (isExpressSameDayTest ? false : isAntigen ? false : true));
        getEmployeeEmail()
        if (isExpressSameDayTest || isRapidTest || isAntigen) {
            setMessage('');
            onFieldUpdate({
                couponId: '',
                discountAmount: 0,
            });
        }
    }, [isExpressSameDayTest, isRapidTest, isAntigen])

    const promoCodeValidation = async () => {
        try {
            setBtnLoading(true);
            const response = await getCoupon({
                code: promoCode,
                email: email,
                testSelection: testSelection
            });

            if (response?.data?.status === 200) {
                onFieldUpdate({ couponId: response.data.couponId });
                if(isEmpEmail){
                    setMessage(
                        `Coupon code applied succesfully, Please click continue.`
                    );
                } else if (isRapidTest) {
                    onFieldUpdate({ discountAmount: (response.data.amount / 100) * 3 });
                    setMessage(
                        `Coupon code applied succesfully. You have got $${(response.data.amount / 100) * 3
                        } as discount. Please click pay now.`
                    );
                } else if (isExpressSameDayTest) {
                    onFieldUpdate({ discountAmount: (response.data.amount / 100) * 2 });
                    setMessage(
                        `Coupon code applied succesfully. You have got $${(response.data.amount / 100) * 2
                        } as discount. Please click pay now.`
                    );
                } else {
                    onFieldUpdate({ discountAmount: response.data.amount / 100 });
                    setMessage(
                        `Coupon code applied succesfully. You have got $${response.data.amount / 100
                        } as discount. Please click pay now.`
                    );
                }
            } else {
                setMessage(response?.data?.message);
                onFieldUpdate({ discountAmount: 0 });
            }
            setBtnLoading(false);
        } catch (e) {
            console.log('error', e);
        }
    }

    const [showRapidAlertModal, setShowRapidAlertModal] = useState(false);
    const handleRapidModalClose = () => {
        setShowRapidAlertModal(false);
    };
    const getEmployeeEmail = async() =>{
        console.log('userEmail', appointment)
        let empData =  await getEmployeeCoupon(appointment?.email)
        const dataEmail: any = appointment?.email?.split('@');
        const worksiteEmail = dataEmail[1];
        if (
            (worksiteEmail === 'worksitelabs.com' || !!empData.data ) &&
            testSelection === 'general'
        ) {
            setIsEmpEmail(true)
            !!setIsEmployeeEmail && setIsEmployeeEmail(true)
            console.log('isEmpEmail', isEmpEmail)
        }
        }


    const locationStartDate = location
        ? parse(location.startDate, config.dateFormat, new Date())
        : null;

    const pageTitle = (standardTest || isExpressSameDayTest || isRapidTest || isAntigen) ? "Pick a date and time" : "Select your test type";
    const { goToPrevStep } = useStepsDispatch();

    const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(isExpressSameDayTest), [isManage]);
    const rollbackisRapidTest = useMemo(() => cloneDeep(isRapidTest), [isManage]);
    // blocking next day after some time period
    let bufferDate:any[]=[]
    let currentTime = new Date();
    let Hour = currentTime.getHours()
    let nextDay = format(addDays(currentTime,1),config.dateFormat).toString()
    let blockOutDayTime:any = location?.blockNextDayAfter?location?.blockNextDayAfter?.replace(/[^0-9]/g, ''):''
    if(blockOutDayTime!==''&&location?.blockNextDayAfter?.endsWith('p'))
    {
        blockOutDayTime = parseInt(blockOutDayTime)+12;        
        if(Hour>=blockOutDayTime)
            if(!(location?.blockOutDays!==undefined&&location?.blockOutDays?.length!==0&&location?.blockOutDays.includes(nextDay)))
            {
                location?.blockOutDays.push(nextDay)
                let bufferdate=[];
                bufferdate.push(nextDay)
                bufferDate=bufferdate;
            }
    }
    else if(blockOutDayTime!==''&&location?.blockNextDayAfter?.endsWith('a'))
    {
        blockOutDayTime = parseInt(blockOutDayTime);
        if(Hour>=blockOutDayTime)
            if(!(location?.blockOutDays!==undefined&&location?.blockOutDays?.length!==0&&location?.blockOutDays.includes(nextDay)))
            {
                location?.blockOutDays.push(nextDay)
                let bufferdate=[];
                bufferdate.push(nextDay)
                bufferDate=bufferdate;
            }
    }
    else
    {
        bufferDate=[];
    }
    // ends blocking next day
    return (
        <Formik
            enableReinitialize
            initialValues={{
                slot,
                departureDateAndTime: departureDateAndTime
                    ? parse(departureDateAndTime, config.dateTimeFormat, new Date())
                    : null,
                isExpressSameDayTest,
                isRapidTest
            }}
            onSubmit={onSchedule}
            validate={(values) => {
                const errors: FormikErrors<FormikValues> = {};
                if (!values.slot) {
                    errors.slot = 'Please select slot';
                }

                return errors;
            }}
        >
            {({ values }) => (
                <Container size="xl">
                    <Content>
                        <HeadarContent>
                            <PageHeader>
                                <PageTitle>{pageTitle}</PageTitle>
                                <PageSubTitle>
                                    <HeaderLocation>
                                        <PinIcon />
                                        <LocationNameAndAddress>
                                            <span style={{ fontWeight: "bold" }}>{location?.name}</span>,
                                            <p>
                                                {location?.address1} {location?.address2}
                                            </p>
                                        </LocationNameAndAddress>
                                        {onLocationChange && (
                                            <ChangeLocationButton type="button" onClick={onLocationChange}>
                                                Change location
                                            </ChangeLocationButton>
                                        )}
                                    </HeaderLocation>
                                </PageSubTitle>
                            </PageHeader>
                            {/* {testSelection !== 'school' && testSelection !== 'community' && (
                            <TestTypeContainer>
                                <InnerBox
                                    selected={isRapidTest}
                                    style={{
                                            border: (!location?.rapidTest || isEmpEmail) ? "1px solid #D3D3D3" : "1px solid rgb(26, 150, 219)",
                                            background: (!location?.rapidTest || isEmpEmail) ? "#D3D3D3" : isRapidTest ? colors.blue : colors.white,
                                            cursor: (!location?.rapidTest || isEmpEmail) ? "not-allowed" : "pointer"
                                        }}
                                        onClick={async () => {

                                        if (!location?.rapidTest || isEmpEmail) {
                                            return
                                        }
                                        upateTestType(false);
                                        updateStandradTest(false);
                                        setShowRapidAlertModal(true);
                                        onFieldUpdate({
                                            slot: undefined,
                                            isExpressSameDayTest: false,
                                            isRapidTest: true,
                                            isAntigen:false
                                        });
                                        if (!values.departureDateAndTime) {
                                            return;
                                        }
                                        // const overTime = new Date(values.departureDateAndTime).getHours();
                                        // const newDate = subHours(values.departureDateAndTime, overTime >= 12 ? 24 : config.rapidSlots.minAdvanceHours)  //values.departureDateAndTime 
                                        onFieldUpdate({
                                            slot: undefined,
                                            isRapidTest: true,
                                            isExpressSameDayTest: false,
                                            date: format(values.departureDateAndTime, config.dateFormat),
                                            rescheduleReservationId: "",
                                            isAntigen:false
                                        });
                                    }} >
                                    <TestTypeTitle selected={isRapidTest} style={{ color: (!location?.rapidTest || isEmpEmail) ? "#939393" : isRapidTest ? colors.white : colors.blue }} >
                                        RAPID TEST
                                    </TestTypeTitle>
                                    <TestCostLabel selected={isRapidTest} style={{ color: (!location?.rapidTest || isEmpEmail) ? "#939393" : isRapidTest ? colors.white : colors.blue }}>
                                        ${convertPricing(false, true, prices, travelType, destination,false, location?.qbenchCustomerId,npDestinations, location?.isNpDestination || false)}
                                    </TestCostLabel>
                                    <TestDurationInfoLabel selected={isRapidTest} style={{ color: (!location?.rapidTest || isEmpEmail) ? "#939393" : isRapidTest ? colors.white : colors.blue }}>
                                        Results within 1.5 hours
                                    </TestDurationInfoLabel>
                                </InnerBox>
                                <InnerBox
                                    selected={isExpressSameDayTest}
                                    style={{
                                        border: !location?.hasVipSlots ? "1px solid #D3D3D3" : "1px solid rgb(26, 150, 219)",
                                        background: !location?.hasVipSlots ? "#D3D3D3" : isExpressSameDayTest ? colors.blue : colors.white,
                                        cursor: !location?.hasVipSlots ? "not-allowed" : "pointer"
                                    }}
                                    onClick={() => {

                                        if (!location?.hasVipSlots) {
                                            return
                                        }

                                        onFieldUpdate({
                                            slot: undefined,
                                            isExpressSameDayTest: true,
                                            isRapidTest: false,
                                            isAntigen:false
                                        });

                                        upateTestType(false);
                                        updateStandradTest(false);

                                        if (!values.departureDateAndTime) {
                                            return;
                                        }

                                        // const overTime = new Date(values.departureDateAndTime).getHours();
                                        // const newDate = subHours(values.departureDateAndTime, overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours)  //values.departureDateAndTime 

                                        onFieldUpdate({
                                            slot: undefined,
                                            isExpressSameDayTest: true,
                                            isRapidTest: false,
                                            date: format(values.departureDateAndTime, config.dateFormat),
                                            rescheduleReservationId: "",
                                            isAntigen:false
                                        });

                                    }} >
                                    {appointment.testSelection === 'sfoairport' && appointment.hasInsurance === true ? (
                                        <TestTypeTitle selected={isExpressSameDayTest} style={{ color: !location?.hasVipSlots ? "#939393" : isExpressSameDayTest ? colors.white : colors.blue, fontSize: '25px' }} >
                                            EXPRESS TEST
                                        </TestTypeTitle>
                                    ) : (
                                        <>
                                            <TestTypeTitle selected={isExpressSameDayTest} style={{ color: !location?.hasVipSlots ? "#939393" : isExpressSameDayTest ? colors.white : colors.blue }} >
                                                EXPRESS TEST
                                            </TestTypeTitle>
                                            <TestCostLabel selected={isExpressSameDayTest} style={{ color: !location?.hasVipSlots ? "#939393" : isExpressSameDayTest ? colors.white : colors.blue }}>
                                                ${convertPricing(true, false, prices, travelType, destination,false, location?.qbenchCustomerId,npDestinations, location?.isNpDestination || false)}
                                            </TestCostLabel>
                                        </>
                                    )}
                                    <TestDurationInfoLabel selected={isExpressSameDayTest} style={{ color: !location?.hasVipSlots ? "#939393" : isExpressSameDayTest ? colors.white : colors.blue }}>
                                        Results within 12 hours
                                    </TestDurationInfoLabel>
                                </InnerBox>
                                {(location?.qbenchCustomerId !== config.qbenchCustomerId.SFOAirport_BARTStation) && ( 
                                <InnerBox
                                    aria-disabled={true}
                                    selected={standardTest}
                                    style={{
                                        // border: isManage && rollbackisExpressSameDayTest ? "1px solid #D3D3D3" : "1px solid rgb(26, 150, 219)",
                                        // background: isManage && rollbackisExpressSameDayTest ? "#D3D3D3" : standard ? colors.blue : colors.white,
                                        // cursor: isManage && rollbackisExpressSameDayTest ? "none" : "pointer"
                                    }}
                                    onClick={() => {

                                        upateTestType(true);
                                        updateStandradTest(true);
                                        onFieldUpdate({
                                            slot: undefined,
                                            isExpressSameDayTest: false,
                                            isRapidTest: false,
                                            isAntigen:false
                                        });

                                        if (!values.departureDateAndTime) {
                                            return;
                                        }

                                        // const newDate = subHours(
                                        //     startOfDay(values.departureDateAndTime),
                                        //     config.maxAdvanceHours
                                        // ); 

                                        upateTestType(true);
                                        updateStandradTest(true);

                                        onFieldUpdate({
                                            slot: undefined,
                                            isExpressSameDayTest: false,
                                            isRapidTest: false,
                                            date: format(values.departureDateAndTime, config.dateFormat),
                                            rescheduleReservationId: "",
                                            isAntigen:false
                                        });

                                    }}>
                                    {appointment.testSelection === 'sfoairport' && appointment.hasInsurance === true ? (
                                        <TestTypeTitle selected={standardTest} style={{ fontSize: '25px' }}>
                                            STANDARD TEST
                                        </TestTypeTitle>
                                    ) : (
                                        <>
                                            <TestTypeTitle selected={standardTest}>
                                                STANDARD TEST
                                            </TestTypeTitle>
                                            <TestCostLabel selected={standardTest}>
                                                ${convertPricing(false, false, prices, travelType, destination,false, location?.qbenchCustomerId,npDestinations, location?.isNpDestination || false)}
                                            </TestCostLabel>
                                        </>
                                    )}
                                    <TestDurationInfoLabel selected={standardTest}>
                                        {includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? "Results within 36 hours" : includes(config.locationsfor72Hrs, location?.qbenchCustomerId)?"Results within 72 hours":"Results within 24 hours"}
                                    </TestDurationInfoLabel>
                                </InnerBox>
                                )}
                                {location?.isAntigen&&<InnerBox
                                    selected={isAntigen}
                                    style={{
                                            border: (!location?.isAntigen || isEmpEmail) ? "1px solid #D3D3D3" : "1px solid rgb(26, 150, 219)",
                                            background: (!location?.isAntigen || isEmpEmail) ? "#D3D3D3" : isAntigen ? colors.blue : colors.white,
                                            cursor: (!location?.isAntigen || isEmpEmail) ? "not-allowed" : "pointer"
                                        }}
                                        onClick={ async () => {

                                            if (!location?.isAntigen || isEmpEmail) {
                                                return
                                            }
                                            onFieldUpdate({
                                                slot: undefined,
                                                isExpressSameDayTest: false,
                                                isRapidTest: false,
                                                isAntigen:true
                                            });
                                            upateTestType(false);
                                            updateStandradTest(false);
                                            if (!values.departureDateAndTime) {
                                                return;
                                            }
                                            const newDate = subHours(
                                                startOfDay(values.departureDateAndTime),
                                                config.maxAdvanceHours
                                            ); 
    
                                            onFieldUpdate({
                                                slot: undefined,
                                                isRapidTest: false,
                                                isExpressSameDayTest: false,
                                                date: format(newDate, config.dateFormat),
                                                reservationId: null,
                                                isAntigen:true
                                            });
                                        }} >
                                        <TestTypeTitle selected={isAntigen?isAntigen:false} style={{ color: (!location?.isAntigen || isEmpEmail ) ? "#939393" : isAntigen ? colors.white : colors.blue }} >
                                            ANTIGEN TEST
                                        </TestTypeTitle>
                                        <TestCostLabel selected={isAntigen?isAntigen:false} style={{ color: (!location?.isAntigen || isEmpEmail) ? "#939393" : isAntigen ? colors.white : colors.blue }}>
                                        ${convertPricing(false, false, prices, travelType, destination,true, location?.qbenchCustomerId,npDestinations, location?.isNpDestination || false)}
                                        </TestCostLabel>
                                        <TestDurationInfoLabel selected={isAntigen?isAntigen:false} style={{ color: (!location?.isAntigen || isEmpEmail) ? "#939393" : isAntigen ? colors.white : colors.blue }}>
                                            Results within 30 minutes
                                        </TestDurationInfoLabel>
                                    </InnerBox>}
                                </TestTypeContainer>
                            )} */}
                        </ HeadarContent>
                        <PageContent>
                            {
                                !standardTest && !isExpressSameDayTest && !isRapidTest && !isAntigen &&
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        onClick={() => {
                                            goToPrevStep();
                                        }}
                                        style={{
                                            width: '24%',
                                        }}
                                        libraryType="default"
                                        type="button"

                                    >
                                        Return
                                    </Button>
                                </div>
                            }

                            <>
                                <Form>
                                    {
                                        // (standardTest || isExpressSameDayTest || isRapidTest || isAntigen) &&
                                        // <AboveColumns>
                                        //     <FormLabel label="Flight departure date & time (use the final leg of departure)">
                                        //         <DatepickerField
                                        //             name="departureDateAndTime"
                                        //             showTimeSelect
                                        //             minDate={
                                        //                 locationStartDate === null ||
                                        //                     isBefore(locationStartDate, new Date())
                                        //                     ? new Date()
                                        //                     : locationStartDate
                                        //             }
                                        //             onChange={(newDepartureDate) => {

                                        //                 //This time zone offset is for pacific daylight time
                                        //                 //Will need to add the other time zones offset later.
                                        //                 // let getTimeZoneOffSet = new Date(newDepartureDate).getTimezoneOffset();

                                        //                 // const overTime = new Date(newDepartureDate).getHours();

                                        //                 // let newDate = values.isExpressSameDayTest
                                        //                 //     ? subHours(newDepartureDate, overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours)  // startOfDay(newDepartureDate)
                                        //                 //     // : subHours(newDepartureDate, config.maxAdvanceHours);
                                        //                 //     : subHours(newDepartureDate, getTimeZoneOffSet == 420 || getTimeZoneOffSet == 300 || getTimeZoneOffSet == 240 ? 71 : config.maxAdvanceHours)

                                        //                 // if (isBefore(newDate, new Date())) {
                                        //                 //     newDate = startOfDay(new Date());
                                        //                 // }

                                        //                 onFieldUpdate({
                                        //                     slot: undefined,
                                        //                     departureDateAndTime: format(
                                        //                         newDepartureDate,
                                        //                         config.dateTimeFormat
                                        //                     ),
                                        //                     date: format(newDepartureDate, config.dateFormat),
                                        //                     rescheduleReservationId: ""
                                        //                 });
                                        //             }}
                                        //         />
                                        //     </FormLabel>
                                        //     {/* {location?.hasVipSlots && (
                                        // <Checkbox
                                        //     name="isExpressSameDayTest"
                                        //     disabled={values.departureDateAndTime === null}
                                        //     onChange={(checked) => {
                                        //     if (!values.departureDateAndTime) {
                                        //         return;
                                        //     }

                                        //     const newDate = checked
                                        //         ? subHours(values.departureDateAndTime, config.vipSlots.minAdvanceHours)  //values.departureDateAndTime 
                                        //         : subHours(
                                        //         startOfDay(values.departureDateAndTime),
                                        //         config.maxAdvanceHours
                                        //         );

                                        //     onFieldUpdate({
                                        //         slot: undefined,
                                        //         isExpressSameDayTest: checked,
                                        //         date: format(newDate, config.dateFormat),
                                        //     });
                                        //     }}
                                        // >
                                        //     Click here to schedule express same-day as flight test for
                                        //     an additional $60 (some restrictions apply).
                                        // </Checkbox>
                                        // )} */}
                                        // </AboveColumns>
                                    }
                                    {values.departureDateAndTime && (
                                        <Columns>
                                            <DatepickerWrapper>
                                                {/* <Datepicker
                                                    value={
                                                        date
                                                            ? parse(date, config.dateFormat, new Date())
                                                            : new Date()
                                                    }
                                                    onChange={(newDate) =>
                                                        onFieldUpdate({
                                                            slot: undefined,
                                                            date: format(newDate, config.dateFormat),
                                                            rescheduleReservationId: ""
                                                        })
                                                    }
                                                    minDate={getMinAvailableDate(
                                                        location,
                                                        values.isExpressSameDayTest,
                                                        values.departureDateAndTime,
                                                        values.isRapidTest,
                                                    )}
                                                    maxDate={getMaxAvailableDate(
                                                        location,
                                                        values.isExpressSameDayTest,
                                                        values.departureDateAndTime,
                                                        values.isRapidTest,
                                                    )}
                                                    inline
                                                /> */}
                                                <Datepicker
                                                    value={
                                                        date
                                                            ? parse(date, config.dateFormat, new Date())
                                                            : new Date()
                                                    }
                                                    onChange={(newDate) =>
                                                        onFieldUpdate({
                                                            slot: undefined,
                                                            reservationId: null,
                                                            date: format(newDate, config.dateFormat),
                                                        })
                                                    }
                                                    isDayFilter={
                                                        testSelection !== 'general'
                                                            ? location?.isDayFilter !== undefined
                                                                ? location?.isDayFilter
                                                                : false
                                                            : false
                                                    }
                                                    isWeeklyFlag={
                                                        testSelection !== 'general'
                                                            ? location?.isWeeklyFlag !== undefined
                                                                ? location?.isWeeklyFlag
                                                                : false
                                                            : false
                                                    }
                                                    weeklyVisible={
                                                        testSelection !== 'general'
                                                            ? {
                                                                weeklyVisibleDays:
                                                                    location?.weeklyVisibleDays !== undefined
                                                                        ? location?.weeklyVisibleDays
                                                                        : [0, 1, 2, 3, 4, 5, 6],
                                                                weeklyVisibleNumber:
                                                                    location?.weeklyVisibleNumber !==
                                                                        undefined
                                                                        ? location?.weeklyVisibleNumber
                                                                        : { weekDay: 0, weekNumber: 0 },
                                                            }
                                                            : {}
                                                    }
                                                    isblockOutDays={
                                                        testSelection !== 'general'
                                                            ? location?.blockOutDays !== undefined ? location?.blockOutDays : bufferDate
                                                            : bufferDate
                                                    }
                                                    minDate={new Date()}
                                                    maxDate={disableFutureDt()}
                                                    inline
                                                />
                                            </DatepickerWrapper>
                                            <SlotsWrapper>
                                                <Slots
                                                    isManage={isManage}
                                                    isAdmin={isAdmin}
                                                    appointment={appointment}
                                                    onFieldUpdate={onFieldUpdate}
                                                />
                                                {isManage &&
                                                    RescheduleTestType.change &&
                                                    appointment.slot &&
                                                    (
                                                        <CouponContainer>
                                                            <FormLabel label="Coupon Code">
                                                                <Inputs
                                                                    name="promoCode"
                                                                    placeholder="Enter The Coupon Code"
                                                                    onChange={(value: any) => {
                                                                        onFieldUpdate({ promoCode: value });
                                                                    }}
                                                                    value={promoCode}
                                                                // validate={(value) => {
                                                                //   if (!value) {
                                                                //     return 'Required field';
                                                                //   }
                                                                // }}
                                                                />
                                                            </FormLabel>
                                                            <FormLabel label="">
                                                                <Button
                                                                    libraryType="primary"
                                                                    type="button"
                                                                    onClick={() => promoCodeValidation()}
                                                                >
                                                                    {btnloading ? 'Processing...' : 'Apply'}
                                                                </Button>
                                                            </FormLabel>
                                                        </CouponContainer>
                                                    )}
                                                {isManage && !isEmployeeEmail &&
                                                    RescheduleTestType.change &&
                                                    appointment.slot && (
                                                        <div style={{ margin: '30px 0', fontWeight: 800 }}>
                                                            Note*
                                                            <br />
                                                            If{' '}
                                                            {RescheduleTestType.upgrade
                                                                ? ' Upgrading '
                                                                : ' Downgrading '}{' '}
                                                            from a {RescheduleTestType.previous} Test to an{' '}
                                                            {RescheduleTestType.present} Test, a full refund
                                                            for your paid {RescheduleTestType.previous} Tests
                                                            will be credited to the original credit card in
                                                            7-15 business days. There will be a new charge for
                                                            the {RescheduleTestType.present} Tests.
                                                        </div>
                                                    )}
                                                {
                                                    (isManage && isAdmin && !isEmployeeEmail) && rollbackisRapidTest == false && isRapidTest == true &&
                                                    <div style={{ margin: "20px 10px 0px 0px" }} >
                                                        <div style={{ fontWeight: "bold" }} >
                                                            *Note
                                                        </div>
                                                        {
                                                            isRapidTest &&
                                                            <RefundInfoText>
                                                                If upgrading from a Standard Test or Express Test to a Rapid Test, a full refund for your paid Standard Tests or Express Tests will be credited to the original credit card in 7-15 business days. There will be a new charge for the Rapid Tests.
                                                            </RefundInfoText>
                                                        }

                                                    </div>
                                                }
                                                <NavigationButtons
                                                    isValid={slot ? true : false}
                                                    loading={loading}
                                                    confirmBtnText={confirmBtnText}
                                                    onReturnButtonClick={() => {
                                                        goToPage(ManagePage.TestResults)
                                                    }}
                                                />
                                            </SlotsWrapper>
                                        </Columns>
                                    )}
                                    {(standardTest || isExpressSameDayTest || isRapidTest || isAntigen) &&
                                        testSelection !== 'flight' && (
                                            <Columns>
                                                <DatepickerWrapper>
                                                    <Datepicker
                                                        value={
                                                            date
                                                                ? parse(date, config.dateFormat, new Date())
                                                                : new Date()
                                                        }
                                                        onChange={(newDate) =>
                                                            onFieldUpdate({
                                                                slot: undefined,
                                                                reservationId: null,
                                                                date: format(newDate, config.dateFormat),
                                                            })
                                                        }
                                                        isDayFilter={
                                                            testSelection !== 'general'
                                                                ? location?.isDayFilter !== undefined
                                                                    ? location?.isDayFilter
                                                                    : false
                                                                : false
                                                        }
                                                        isWeeklyFlag={
                                                            testSelection !== 'general'
                                                                ? location?.isWeeklyFlag !== undefined
                                                                    ? location?.isWeeklyFlag
                                                                    : false
                                                                : false
                                                        }
                                                        weeklyVisible={
                                                            testSelection !== 'general'
                                                                ? {
                                                                    weeklyVisibleDays:
                                                                        location?.weeklyVisibleDays !== undefined
                                                                            ? location?.weeklyVisibleDays
                                                                            : [0, 1, 2, 3, 4, 5, 6],
                                                                    weeklyVisibleNumber:
                                                                        location?.weeklyVisibleNumber !==
                                                                            undefined
                                                                            ? location?.weeklyVisibleNumber
                                                                            : { weekDay: 0, weekNumber: 0 },
                                                                }
                                                                : {}
                                                        }
                                                        minDate={new Date()}
                                                        maxDate={disableFutureDt()}
                                                        inline
                                                    />
                                                </DatepickerWrapper>
                                                <SlotsWrapper>
                                                    <Slots
                                                        isManage={isManage}
                                                        isAdmin={isAdmin}
                                                        appointment={appointment}
                                                        onFieldUpdate={onFieldUpdate}
                                                    />
                                                    {isManage &&
                                                        RescheduleTestType.change &&
                                                        appointment.slot &&
                                                        testSelection !== 'school' && testSelection !== 'community' && testSelection !== 'sfoairport' && (
                                                            <CouponContainer>
                                                                <FormLabel label="Coupon Code">
                                                                    <Inputs
                                                                        name="promoCode"
                                                                        placeholder="Enter The Coupon Code"
                                                                        onChange={(value: any) => {
                                                                            onFieldUpdate({ promoCode: value });
                                                                        }}
                                                                        value={promoCode}
                                                                    // validate={(value) => {
                                                                    //   if (!value) {
                                                                    //     return 'Required field';
                                                                    //   }
                                                                    // }}
                                                                    />
                                                                </FormLabel>
                                                                <FormLabel label="">
                                                                    <Button
                                                                        libraryType="primary"
                                                                        type="button"
                                                                        onClick={() => promoCodeValidation()}
                                                                    >
                                                                        {btnloading ? 'Processing...' : 'Apply'}
                                                                    </Button>
                                                                </FormLabel>
                                                            </CouponContainer>
                                                        )}
                                                    {message && (
                                                        <Error
                                                            style={{
                                                                
                                                                color: ((discountAmount ? (discountAmount): -1) > 0) || 
                                                                    (isEmpEmail && message !== "Invalid coupon code") ? 'green' : 'red',
                                                            }}
                                                        >
                                                            {message}
                                                        </Error>
                                                    )}
                                                    {isManage && !isEmployeeEmail &&
                                                        RescheduleTestType.change &&
                                                        appointment.slot && (
                                                            <div
                                                                style={{ margin: '30px 0', fontWeight: 800 }}
                                                            >
                                                                Note*
                                                                <br />
                                                                If{' '}
                                                                {RescheduleTestType.upgrade
                                                                    ? ' Upgrading '
                                                                    : ' Downgrading '}{' '}
                                                                from a {RescheduleTestType.previous} Test to an{' '}
                                                                {RescheduleTestType.present} Test, a full refund
                                                                for your paid {RescheduleTestType.previous}{' '}
                                                                Tests will be credited to the original credit
                                                                card in 7-15 business days. There will be a new
                                                                charge for the {RescheduleTestType.present}{' '}
                                                                Tests.
                                                            </div>
                                                        )}
                                                    {
                                                        (isManage && isAdmin && !isEmployeeEmail) && rollbackisRapidTest == false && isRapidTest == true &&
                                                        <div style={{ margin: "20px 10px 0px 0px" }} >
                                                            <div style={{ fontWeight: "bold" }} >
                                                                *Note
                                                            </div>
                                                            {
                                                                isRapidTest &&
                                                                <RefundInfoText>
                                                                    If upgrading from a Standard Test or Express Test to a Rapid Test, a full refund for your paid Standard Tests or Express Tests will be credited to the original credit card in 7-15 business days. There will be a new charge for the Rapid Tests.
                                                                </RefundInfoText>
                                                            }

                                                        </div>
                                                    }
                                                    <NavigationButtons
                                                        loading={loading}
                                                        confirmBtnText={confirmBtnText}
                                                        onReturnButtonClick={() => {
                                                            goToPage(ManagePage.TestResults)
                                                        }}
                                                    />
                                                </SlotsWrapper>
                                            </Columns>
                                        )}
                                </Form>

                            </>
                        </PageContent>
                    </Content>
                    <ChangeLocationModal openModal={() => setShowChangeDatetimeModal(false)} />
                    <LocationModal />
                    <RapidAlertModal
                        handleModalClose={handleRapidModalClose}
                        open={showRapidAlertModal}
                    />
                </Container>
            )}
        </Formik>
    );
};

export default Schedule;

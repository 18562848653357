const config = {
    baseURL: `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`,
    qbenchCustomerId: {
        LasVegas_TheOrleans: process.env.LasVegas_TheOrleans,
        SFO_Airport: process.env.SFO_Airport,
        Oakland_HolidayInnExpress: process.env.Oakland_HolidayInnExpress,
        PostIsolationRapidAntigenTest: process.env.PostIsolationRapidAntigenTest,
        SFOAirport_BARTStation: process.env.SFOAirport_BARTStation,
    },
    firestoreCollections: {
        schedules: 'schedules',
        appointments: 'appointments',
        locations: 'locations',
        onetimepassword: 'onetimepassword',
        qbenchacknowledgement: 'qbenchacknowledgement',
        postalcodes: 'postalcodes',
        reschedulerefunds: 'reschedulerefunds',
        schedulesrapid: 'schedulesrapid',
    },
    firebase: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    },
    weekDateFormat: 'EEEE, MMM d, yyyy',
    dateTimeFormat: 'LL/dd/yyyy hh:mm a',
    dateFormat: 'LL/dd/yyyy',
    fileDateFormat: 'yyyyLLdd',
    maxSlotsByPeriod: 16,
    schedule: {
        startTime: '6:00a',
        endTime: '10:00p',
        periodDuration: 30,
    },
    Reportschedule: {
        startTime: '6:00a',
        endTime: '10:00p',
        periodDuration: 30,
    },
    rapidSchedule: {
        startTime: '6:00a',
        endTime: '10:00p',
        periodDuration: 60,
    },
    products: {
        standard: process.env.REACT_APP_STANDARD_TEST_ID,
        expedited: process.env.REACT_APP_EXPEDITED_TEST_ID,
        rapid: process.env.REACT_APP_RAPID_TEST_ID,
        antigen: process.env.REACT_APP_ANTIGEN_TEST_ID,
        standard_INT: process.env.REACT_APP_INT_STANDARD_TEST_ID,
        expedited_INT: process.env.REACT_APP_INT_EXPEDITED_TEST_ID,
        rapid_INT: process.env.REACT_APP_INT_RAPID_TEST_ID,
        antigen_INT: process.env.REACT_APP_INT_ANTIGEN_TEST_ID,
    },
    currency: 'usd',
    maxAdvanceHours: 72,
    minAdvanceHours: 48,
    vipSlots: {
        maxAdvanceHours: 72,
        minAdvanceHours: 12,
        lastExpressSlotIndex: 12,
    },
    rapidSlots: {
        maxAdvanceHours: 72,
        minAdvanceHours: 3,
        lastExpressSlotIndex: 12,
    },
    lastExpressSlotIndex: 1,
    reportData: {
        location: '450 Bauchet Street, Los Angeles CA 90012',
        reviewedBy: 'Anthony Victorio, MD',
        technician: 'Cherisse Heirs',
    },
    manageScreen: {
        upcoming: 5,
        inProcess: 4,
        completed: 3,
        missed: 2,
    },
    locationsfor36Hrs: [140, 162, 161, 160, 148, 147],
    locationsfor72Hrs: [145],
    internationalPricing: {
        destinations: [
            'JPN',
            'KNA',
            'ATG',
            'BRB',
            'CHN',
            'MAF',
            'TCA',
            'JAM',
            'NAN',
        ],
        locations: [10, 33, 23, 2, 22, 220],
    },
    pauseBooking: {
        destinations: ['JPN', 'KNA', 'ATG', 'BRB', 'CHN', 'MAF', 'TCA'],
        locations: [34],
    },
    pauseSameDayBooking: {
        locations: [145, 148, 147, 161, 160],
    },
};

export { config };

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { Form, Formik, FormikErrors } from 'formik';
import circleRectAngleBottom from '../../../assets/circle+rectangle-teal-bottom.png';
import { makeStyles } from '@material-ui/core';

import {
    Button,
    Container,
    Content,
    PageHeader,
    PageSubTitle,
    PageTitle,
} from '../../shared/components/styled';
import { useManageDispatch } from '../provider';
import { isUSPhone, isValidEmail } from '../../../validations';
import { ManagePage } from '../dictionaries';
import {
    getAppointmentByPhoneAndDate,
    getAppointmentByConfirmationId,
    getAppointmentByPhoneorEmail,
    getQbenchOrderById,
    initiateOTP,
    validateOTP
} from '../api';
import { DatepickerField } from '../../shared/components/Datepicker';
import { colors } from '../../../styles/colors';
import { Appointment } from '../../shared/models';
import { appointmentList } from '../models';
import { config } from '../../../config';

import Input from '../../shared/components/form/Input';
import FormLabel from '../../shared/components/form/FormLabel';
import Checkbox from '../../shared/components/form/Checkbox';
import { Breakpoints } from '../../../dictionaries';


interface NoticeProps {
    margin?: string;
}

const useStyles = makeStyles({
    selectInput: {
        borderRadius: '10px',
        fontSize: '16px',
        lineHeight: '24px',
        padding: '8px 28px',
        transition: ' all 0.15s ease-in-out',
        width: '100%',
        border: '0px solid gray',
        pointerEvents: 'unset',
    },
});


const Notice = styled.p`
  margin: ${({ margin }: NoticeProps) => margin || '27px 0 65px'};
  font-size: 14px;
`;

const Agreement = styled.p`
  margin: 0 0 24px 0;
`;

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;

const Divider = styled.h2`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #101010;
  line-height: 0.1em;
  margin: 30px 0 20px 0;
  color: #101010;
  font-size: 16px;
  font-weight: 700;
`;

const DividerSpan = styled.span`
  background: #fff;
  padding: 0 10px;
`;

const ResentText = styled.div`
  cursor: pointer;
  text-align: center;
  /* text-decoration: underline; */
  text-align: initial;
  &:hover {
    color: #000;
  }
  p {
    margin-bottom: 1em;
  }
  b {
    text-decoration: underline;
    color: #004069;
  }
`;
const Card = styled.div`
  display: flex;
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.5 : 1)};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  border-radius: 15px;
  padding: 10px;
`;
const Background = styled.div`
  width: 100vw;
  z-index: 10;
  background-repeat: no-repeat;
  height: 8vh;
  @media (max-width: ${Breakpoints.md}px) {
    background-position: bottom;
    background-image: url(${circleRectAngleBottom});
    background-size: 100% 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    background-size: 100% 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;
interface ButtonProps {
    libraryType: 'primary' | 'default';
    accentColor?: string;
    size?: 'sm' | 'lg';
  }

const ReturnButton = styled.button`
    border: 1px solid
    ${({ accentColor }: ButtonProps) => accentColor || colors.darkBlue};
    font-size: ${({ size }: ButtonProps) => (size === 'sm' ? '14px' : '20px')};
    line-height: 1.6;
    border-radius: 5px;
    padding: ${({ size }: ButtonProps) =>
    size === 'sm' ? '4px 15px' : '9px 20px'};
    font-weight: ${({ size }: ButtonProps) =>
    size === 'sm' ? 'normal' : 'bold'};
    width: 100%;
    text-align: center;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
    return libraryType === 'primary'
    ? colors.white
    : accentColor || colors.darkBlue;
    }};
    background: ${({ libraryType, accentColor }: ButtonProps) =>
    libraryType === 'primary' ? accentColor || colors.darkBlue : colors.white};
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:disabled {
    background: ${({ libraryType, accentColor }: ButtonProps) =>
    libraryType === 'primary'
        ? accentColor || colors.darkBlue50
        : colors.white};
    border: 1px solid transparent;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
    return libraryType === 'primary'
        ? colors.white
        : accentColor || colors.darkBlue50;
    }};
    }
`;

function normalizeResultName(result: string) {
    const res = result.toLowerCase();

    if (res === 'detected') {
        return 'POSITIVE';
    }

    return 'NEGATIVE';
}

const VerifyIdentity: React.FC = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [isOTP, setOTPState] = useState(false)

    const [countryCode, setCountryCode] = useState<string>('+1');
    const [phoneNumber, setPhoneNumber] = useState('+1 United States');

    const [isOTPResent, setResentOTPState] = useState(false)

    const { goToPage, updateResultsScreen, setMinorAccount,setMinorEmail } = useManageDispatch();
    const [{email, isSchedularLite}, setDefault] = useState(Object.fromEntries(new URLSearchParams(window.location.search).entries()))
    interface FormValues {
        phone: string;
        email: string;
        otp: string;
    }

    const phoneNumberStyle: any = {
        borderRadius: '10px',
        fontSize: '16px',
        lineHeight: '24px',
        padding: '4px 16px',
        transition: ' all 0.15s ease-in-out',
        width: '100%',
        border: '1px solid gray',
        marginBottom: '12px',
    };

    const handleCountryCode = (selectedCode: string) => {
        setCountryCode(selectedCode);
    };

    const validateForm = (values: any) => {

        let errors: FormikErrors<FormValues> = {};

        if (values.email == "" && values.phone == "") {
            errors.phone = 'Required field';
            errors.email = 'Required field';
        }

        if (values.email !== "" && !isValidEmail.test(values.email)) {
            errors.email = 'Please enter valid email address';
        }

        if (values.phone !== '' && !isUSPhone.test(values.phone)) {
            errors.phone = 'Please enter valid US phone number';
        }
        if (values.email !== "") {
            if (!isValidEmail.test(values.email)) {
                errors.email = 'Please enter valid email address';
            }
        }

        if (isOTP && values.otp == "") {
            errors.otp = 'Please enter valid verfication code sent to your Email or Phone';
        }

        if (values.email !== '' && values.phone !== '') {
            setError('Please use any one of the method to continue.');

        }

        return errors;
    };


    async function getAppointments(otp: string, phone: string, email: string, minor: boolean) {
        return new Promise(async (resolve, reject) => {
            let validation: any =false 
            if(!isSchedularLite)
                validation = await validateOTP(otp.trim(), phone.trim(), email.trim());

            // const validation = true;

            if (validation || isSchedularLite) {
                let result: any = [];
                if ((email === '' && phone !== '') || (email !== '' && phone === '')) {
                    setLoading(true);
                    setError(null);

                    if (email) {
                        result = await getAppointmentByPhoneorEmail("", email.trim(),minor, isSchedularLite);
                        setMinorEmail(email);
                        setMinorAccount(minor);
                    } else if (phone) {
                        result = await getAppointmentByPhoneorEmail(phone.trim(), "",null, isSchedularLite);
                    }
                    setLoading(false);

                    if (result.length > 0) {
                        setLoading(false);
                        updateResultsScreen(result);
                        goToPage(ManagePage.TestResults);
                    }else{
                        setError('Appointment is not found');
                    }

                } else {
                    setError('Pleasse use any one of the method to continue.');
                }

            } else {
                setError("Invalid verification code");
                setResentOTPState(true);
            }
        })
    }
    useEffect(() => {
    if(isSchedularLite && email)
        getAppointments('','',email,false)
    }, [])
    return (
        <>
        <Container style={{minHeight:'78.9vh'}}>
            <Content>
                <PageHeader>
                    <PageTitle>{isOTP ? 'Enter your verification code' : 'Choose one method to verify your identity.'}</PageTitle>
                    <PageSubTitle>
                         { isOTP ?
                                <p>A verfication code has just been sent to you. </p> :
                                <p>One quick step to protect your privacy.</p>
                            }
                    </PageSubTitle>
                </PageHeader>
                <Formik
                    initialValues={{
                        email:  email ?? '',
                        phone: "",
                        otp: "",
                        minor:false,
                    }}
                    onSubmit={async ({
                        phone,
                        email,
                        minor
                    }: {
                        phone: string;
                        email: string;
                        otp: string;
                        minor:boolean
                    }) => {

                        if ((email === '' && phone !== '') || (email !== '' && phone === '')) {
                            if (!isOTP) {
                                const result: any = initiateOTP(phone, email.toLowerCase());
                                // const result = true;
                                if (result) {
                                    setOTPState(true)
                                }
                            }

                        } else {
                            setError('Pleasse use any one of the method to continue.');
                        }
                    }}
                    enableReinitialize={false}
                    validate={validateForm}
                >
                    {({ values }) => (
                        <Form>
                            {
                                !isOTP &&
                                <>
                                    <h2 style={{margin:'5px',fontWeight:600}}>Email</h2>
                                     <Card>
                                            <FormLabel label="Email">
                                                <Input
                                                    name="email"
                                                    noBottomMargin
                                                    value={isSchedularLite ? (email ?? '') : values.email}
                                                // disabled={isOTP}
                                                /><br/>
                                                <Checkbox name='minor' >
                                                    <p> I am a Minor</p>
                                                </Checkbox>
                                            </FormLabel>
                                        </Card>
                                    {/* <Divider>
                                        <DividerSpan>OR</DividerSpan>
                                    </Divider>
                                    <FormLabel label="Mobile Number">
                                        <Input
                                            name="phone"
                                            noBottomMargin
                                        />
                                        <Notice margin={"27px 0 35px"} >
                                            The same 10-digit number you used when scheduling.
                                    </Notice>
                                    </FormLabel> */}

                                    {/* Phone validation -> START */}
                                    <Divider>
                                        <DividerSpan>or</DividerSpan>
                                    </Divider>
                                    <h2 style={{margin:'5px',fontWeight:600}}>Text Message</h2>
                                    <Card>
                                        <FormLabel label="Select Country code">
                                            <input
                                                type="hidden"
                                                name="code"
                                                value={countryCode}
                                            />
                                            <IntlTelInput
                                                preferredCountries={['us']}
                                                style={phoneNumberStyle}
                                                inputClassName={classes.selectInput}
                                                onSelectFlag={(
                                                    num: any,
                                                    country: any
                                                ) => {
                                                    const selectedCode: any =
                                                        '+' + country.dialCode;
                                                    handleCountryCode(selectedCode);
                                                    setPhoneNumber(
                                                        selectedCode +
                                                            ' ' +
                                                            country.name
                                                    );
                                                }}
                                                value={phoneNumber}
                                                placeholder="+1 United States States"
                                            />
                                            <Notice margin={'4px 0 16px'}>
                                                enter 6-digit verification code
                                            </Notice>
                                            <Input
                                                name="phone"
                                                placeholder="Mobile Number"
                                                noBottomMargin
                                            />

                                            <Notice margin={'18px 0 35px'}>
                                                The same 10-digit number you used
                                                when scheduling.
                                            </Notice>
                                        </FormLabel>
                                    </Card>

                                    {/* Phone validation -> END */}
                                </>
                            }

                            {isOTP &&
                                // <FormLabel label="Enter your verfication code sent to your email or phone">
                                <Card>
                                    <br/>
                                    <FormLabel label="">
                                    <Input
                                        placeholder='XX-XX-XX'
                                        name="otp"
                                        //noBottomMargin
                                    />
                                    <Notice margin={'4px 16px'}>
                                              {/* <p> By default the country code is ( +1
                                                - United States )</p> */}
                                                enter 6-digit verification code
                                        </Notice>
                                    </FormLabel>
                                </Card>    

                            }
                            <br />
                            {isOTP && isOTPResent &&
                                <ResentText
                                    onClick={() => {

                                        if ((values.email === '' && values.phone !== '') || (values.email !== '' && values.phone === '')) {
                                            if (isOTP) {
                                                const result: any = initiateOTP(values.phone, values.email.toLowerCase());
                                                // const result = true;
                                                if (result) {
                                                    // setOTPState(true)
                                                    setError(null);
                                                    setResentOTPState(false)
                                                }
                    
                                            } else {
                                                setError('Pleasse use any one of the method to continue.');
                                            }
                
                                        } else {
                                            setError('Pleasse use any one of the method to continue.');
                                        }

                                    }}
                                >
                                    <p>Did not get it?</p>
                                    <b> Resend Code </b>
                                </ResentText>
                            }
                            <br />
                            <Agreement>
                                By verifying this information you legally confirm that you are
                                or are acting as caregiver for this person.
                            </Agreement>
                            {
                                isOTP ?
                                    <div style={{ display: "flex", }}>
                                        <Button disabled={loading} libraryType="default" onClick={() => { setOTPState(false); setResentOTPState(false); setError(null); }} >
                                            Return
                                        </Button>
                                        <div style={{ margin: "10px" }} />
                                        <Button onClick={() => { getAppointments(values.otp, values.phone, values.email.toLowerCase(),values.minor); setResentOTPState(false); }} libraryType="primary" disabled={loading}>
                                            {loading ? 'Processing...' : 'Verify Identity'}
                                        </Button>
                                    </div>
                                    :
                                    <Button type="submit" libraryType="primary" disabled={loading}>
                                        {loading ? 'Processing...' : 'Get Verfication Code'}
                                    </Button>
                            }
                            {error && <Error>{error}</Error>}                
                        </Form>
                    )}
                </Formik>
            </Content>
        </Container>
        <Background/>
        </>
    );

};

export default VerifyIdentity;

import React from 'react';
import { Switch, Route } from 'react-router-dom';


import Manage from './modules/manage/components/Manage';


function App() {
  return (
    <Switch>
    <Route path="/" extact>
      <Manage />
    </Route>
  </Switch>
  );
}

export default App;

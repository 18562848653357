import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
    format,
    startOfDay,
    isEqual,
    isBefore,
    isAfter,
    set,
    subHours,
    subMinutes,
    parse,
    differenceInDays,
    isSameDay,
    addMinutes,
    addDays
} from 'date-fns';
import { Field, FieldProps } from 'formik';

import { colors } from '../../../styles/colors';
import { ErrorText } from './styled';
import { Period } from '../providers/schedule/models';
import { AnyObject, Appointment } from '../models';
import { Breakpoints } from '../../../dictionaries';
import { config } from '../../../config';
import { subscribeOnAppointmentsUpdate, reserveSlot, getNewSlots } from '../../shared/providers/schedule/api';
import { pluralize, getPeriodsFromSchedule, timeDifferenceFilter, weekAndWeekDayConvertion} from '../../../utils';
import { getMinAvailableDate, getMaxAvailableDate } from './Schedule';

import Datepicker from './Datepicker';
import { useStepsDispatch, useStepsState } from '../../shared/providers/schedule/provider';
import { useManageDispatch, useManageState } from '../../manage/provider';
import { useAdminDispatch, useAdminState } from '../../shared/providers/admin/provider';
import { includes } from 'lodash';

const periods = getPeriodsFromSchedule(config.schedule);


const Container = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 23px;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const SlotsContainer = styled.div`
  background: ${colors.white};
  padding: 30px;
  position: relative;
  border: 0.5px solid #ff802e;
  border-radius: 12px;
  @media (min-width: ${Breakpoints.lg}px) {
    padding: 80px 60px;
  }
`;

const Slot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -5px 0 30px;

  & > * {
    margin: 5px 0;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    flex-direction: row;
    margin: 0 -17px 20px;

    & > * {
      margin: 0 17px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ChooseSlotBtn = styled.button`
  background: ${({ selected }: { selected: boolean }) =>
    selected ? colors.renderBlue : colors.white};
  color: ${({ selected }: { selected: boolean }) =>
    selected ? colors.white : colors.renderBlue};
  border: 1px solid ${colors.renderBlue};
  text-transform: uppercase;
  font-size: 20px;
  transition: all 0.15s ease-in-out;
  padding: 10px 30px;
  border-radius: 10px;
  white-space: nowrap;
  cursor: ${({ selected }: { selected: boolean }) =>
    selected ? 'default' : 'pointer'};

  @media (min-width: ${Breakpoints.sm}px) {
    padding: 10px 50px;
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 10px 80px;
    width: 350px;
  }
  @media (min-width: ${Breakpoints.sm}px) {
    :hover {
      background-color: ${colors.renderBlue};
      color: white;
    }
  }
  &:disabled {
    color: ${({ selected }: { selected: boolean }) =>
      selected ? colors.white : colors.grey};
    border: 1px solid
      ${({ selected }: { selected: boolean }) =>
        selected ? colors.renderBlue : colors.grey};
    cursor: default;
  }
`;

const Error = styled.div`
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
`;

const DatePickerContainer = styled.div`
  display: block;
  line-height: 1.5;

  @media (min-width: ${Breakpoints.md}px) {
    display: ${({ alwaysShowDatePicker }: { alwaysShowDatePicker?: boolean }) =>
        alwaysShowDatePicker ? 'block' : 'none'};
  }
`;

const ChangeDateBtn = styled.div`
  display: inline-block;
  border: 0;
  background: none;
  color: ${colors.renderBlue};
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 2px;
`;

const SelectedDate = styled.h3`
  color: rgba(38, 38, 38, 0.8);
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  margin-right: 10px;
`;

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const Shimmer = styled.div`
  height: 13px;
  width: 120px;
  border-radius: 2px;
  animation: ${shimmer} 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 400px 100%;
`;

interface Props {
    alwaysShowDatePicker?: boolean;
    appointment: Appointment;
    onFieldUpdate: (update: AnyObject) => void;
    onChangeDate?: (data: any) => void;
    isAdmin?: boolean;
    isManage?: any;
}

const Slots: React.FC<Props> = ({
    alwaysShowDatePicker,
    appointment,
    onFieldUpdate,
    onChangeDate,
    isAdmin,
    isManage
}) => {

    // const periods = getPeriodsFromSchedule(config.schedule);


    const [loading, setLoading] = useState(false);
    const [slots, setSlots] = useState<any>(periods);



    const {
        date,
        slot,
        minors,
        departureDateAndTime,
        isExpressSameDayTest,
        isRapidTest,
        location,
        reservationId,
        reservationCount,
        rescheduleReservationId,
        testSelection,
        isAntigen
    } = appointment;
    const numberOfGuests = minors.length + 1;

    const { slotsList } = useStepsState();
    const {updateFormValues, updateSlotsList}=useStepsDispatch();
    const { adminSlots } = useAdminState();
    const { rescheduleslots } = useManageState();
    const {updateRescheduleSlots} = useManageDispatch();
    const {updateAdminSlots} = useAdminDispatch();
    const locationId = location?.qbenchCustomerId;

    const [selectedDate, setSelectedDate] = useState(date ? parse(date, config.dateFormat, new Date()) : new Date());

    const getNewAPI = async () => {

        const response = await getNewSlots({

            testType: isRapidTest ? 'rapid' : isExpressSameDayTest ? 'express' : isAntigen ? 'antigen' : 'standard',
             locationId: appointment.location?.qbenchCustomerId
         })
         updateRescheduleSlots(response.data);
         updateSlotsList(response.data);
         updateAdminSlots(response.data);

    }
    useEffect(() => {
        getNewAPI()
    }, [isExpressSameDayTest, location, locationId, isRapidTest, isAntigen])

    function checkIsWeekDay(date: any) {
        let validDay: any = "";
        let day: any = new Date(date).getDay();
        let blockOutDays: any = location?.blockOutDays !== undefined ? includes(location?.blockOutDays, format(new Date(date), config.dateFormat)) : false;
        let isDayFilter: any = testSelection !== "general" ? location?.isDayFilter !== undefined ? location?.isDayFilter : false : false
        let isWeeklyFlag: any = testSelection !== "general" ? location?.isWeeklyFlag !== undefined ? location?.isWeeklyFlag : false : false
        let weeklyVisible: any = testSelection !== "general" ? {
            weeklyVisibleDays: location?.weeklyVisibleDays !== undefined ? location?.weeklyVisibleDays : [0, 1, 2, 3, 4, 5, 6],
            weeklyVisibleNumber: location?.weeklyVisibleNumber !== undefined ? location?.weeklyVisibleNumber : { weekDay: 0, weekNumber: 0 },
        }
            : {}

        if (isWeeklyFlag !== undefined && isWeeklyFlag) {
            let addDay: any = weekAndWeekDayConvertion({
                weeklyVisible: weeklyVisible,
                date: date,
            });
            if (isDayFilter) {
                if (blockOutDays !== undefined && blockOutDays) {
                    validDay = false;
                }
                else if (new Date(date).getDate() === new Date(addDay).getDate()) {
                    validDay = true;
                } else {
                    validDay = false;
                }
            } else {
                if (blockOutDays !== undefined && blockOutDays) {
                    validDay = false;
                }
                else if (includes(weeklyVisible.weeklyVisibleDays, day)) {
                    validDay = true;
                } else {
                    validDay = false;
                }
            }
        } else if (blockOutDays !== undefined && blockOutDays) {
            validDay = false;
        } else {
            validDay = true
        }

        return validDay
    }

    useEffect(() => {
        setLoading(true);

        let unsubscribe: any;

        async function getSlots() {
            try {
                if (date && locationId) {
                    const parsed = parse(date, config.dateFormat, new Date());
                    const dayAsNumber = new Date(parsed).getDay();
                

                    let updatedPeriods: any = []
                    if (!isAdmin && !isManage) {

                        slotsList.map((item: any) => {
                            let testType:any = isRapidTest ? "rapid" : (isExpressSameDayTest ? "express": isAntigen ? "antigen" : "standard")
                            if(item.testType ==  testType){
                                // item[`${location?.qbenchCustomerId}`].slotsByDay[`${dayAsNumber}`]
                                item.slotsByDay[`${dayAsNumber}`]
                                .map((res: any)=>{
                                    updatedPeriods.push({
                                        ...res,
                                        startTime: parse(res.startTime, 'h:mmaaaaa', new Date())
                                    })        
                                })
                            }
                            
                        })

                    } else if(isManage) {

                        rescheduleslots.map((item: any) => {
                            let testType:any = isRapidTest ? "rapid" : (isExpressSameDayTest ? "express": isAntigen ? "antigen" :  "standard")
                            if(item.testType ==  testType){
                                // item[`${location?.qbenchCustomerId}`].slotsByDay[`${dayAsNumber}`]
                                item.slotsByDay[`${dayAsNumber}`]
                                .map((res: any)=>{
                                    updatedPeriods.push({
                                        ...res,
                                        startTime: parse(res.startTime, 'h:mmaaaaa', new Date())
                                    })        
                                })
                            }
                            
                        })

                    }else if(isAdmin) {

                        adminSlots.map((item: any) => {
                            let testType:any = isRapidTest ? "rapid" : (isExpressSameDayTest ? "express": isAntigen ? "antigen" : "standard")
                            if(item.testType ==  testType){
                                // item[`${location?.qbenchCustomerId}`].slotsByDay[`${dayAsNumber}`]
                                item.slotsByDay[`${dayAsNumber}`]
                                .map((res: any)=>{
                                    updatedPeriods.push({
                                        ...res,
                                        startTime: parse(res.startTime, 'h:mmaaaaa', new Date())
                                    })        
                                })
                            }
                        })

                    } else{
                        updatedPeriods=[]
                    }

                    unsubscribe = subscribeOnAppointmentsUpdate(
                        { date, locationId, isRapidTest },
                        (result: any) =>
                            setSlots(
                                updatedPeriods.reduce((acc: any, period: any, i: any) => {
                                    const a = acc;

                                    let starttime: any = period.startTime
                                    var dateNow = new Date()

                                    const startTime = set(starttime, {
                                        year: parsed.getFullYear(),
                                        date: parsed.getDate(),
                                        month: parsed.getMonth(),
                                    });

                                    var startDate: any = location?.startDate

                                    //Check theweek day filter for slot days
                                    let filterWeekDay: any = checkIsWeekDay(startTime);

                                    //Stop Oakland booking
                                    let blockDate: any = new Date('12/24/2021');
                                    let blockLocation: any =
                                        isBefore(blockDate, startTime) &&
                                        location?.qbenchCustomerId === Number(config.qbenchCustomerId.Oakland_HolidayInnExpress)
                                            ? false
                                            : true;

                                    if (!departureDateAndTime && testSelection === 'flight' && blockLocation) {
                                        return a;
                                    }

                                    let DateValidation = location !== null && location.qbenchCustomerId === Number(config.qbenchCustomerId.SFO_Airport) ?
                                        isSameDay(new Date("11/23/2021"), startTime) ? true : false
                                        : false;

                                    

                                    if (testSelection === 'general' && location !== null && location.qbenchCustomerId === Number(config.qbenchCustomerId.SFO_Airport) && DateValidation) {
                                        return a;
                                    }

                                    if (departureDateAndTime) {
                                        const parsedDeparture = parse(
                                            departureDateAndTime,
                                            config.dateTimeFormat,
                                            new Date()
                                        );

                                        // let slotIsOutOfAllowedPeriod: any = "";

                                        // if (isRapidTest) {

                                        //     slotIsOutOfAllowedPeriod =
                                        //         isBefore(
                                        //             startTime,
                                        //             subHours(parsedDeparture, 72)
                                        //         ) ||
                                        //         isAfter(
                                        //             startTime,
                                        //             subMinutes(parsedDeparture, 1)
                                        //         );

                                        // } else {

                                        //     slotIsOutOfAllowedPeriod =
                                        //         isBefore(
                                        //             startTime,
                                        //             subHours(parsedDeparture, isExpressSameDayTest ? config.vipSlots.maxAdvanceHours : config.maxAdvanceHours)
                                        //         ) ||
                                        //         isAfter(
                                        //             startTime,
                                        //             subHours(parsedDeparture, isExpressSameDayTest ? config.vipSlots.minAdvanceHours : config.minAdvanceHours)
                                        //         );

                                        // }

                                        // if (slotIsOutOfAllowedPeriod) {
                                        //     return a;
                                        // }

                                        // let validateStandard: any = isBefore(dateNow, startTime);
                                        // if (!isExpressSameDayTest && !isRapidTest) {
                                        //     validateStandard = (isBefore(dateNow, startTime) && !isSameDay(new Date(), startTime));
                                        // }

                                        //Action for block out days
                                        let DateValidation = location?.blockOutDays !== undefined
                                            ? includes(location.blockOutDays, format(startTime, config.dateFormat))
                                                ? false
                                                : true
                                            : true


                                        if (isBefore(dateNow, addMinutes(startTime, 15)) && DateValidation && blockLocation) {

                                            if (isAfter(parsedDeparture, new Date(startDate)) && (isAfter(parsed, new Date(startDate)) || isEqual(parsed, new Date(startDate)))) {

                                                a.push({
                                                    ...period,
                                                    startTime,
                                                    index: period.index,
                                                    available:
                                                        period.available -
                                                        ((result && result.data.appointmentsPerPeriod?.[period.index]) || 0),
                                                });
                                            }
                                        }
                                    } else {

                                        //Add the filter 
                                        let alloWDayFilter: any = testSelection !== "general" ? filterWeekDay : true

                                        let blockOutDaysValidation = location?.blockOutDays !== undefined
                                            ? includes(location.blockOutDays, format(startTime, config.dateFormat))
                                                ? false
                                                : true
                                            : true

                                        // Sameday booking removed for the location in general flow
                                        if (testSelection === 'general' && includes(config.pauseSameDayBooking.locations, locationId) && blockOutDaysValidation && blockLocation) {

                                            if (isBefore(new Date(), parsed)) {

                                                a.push({
                                                    ...period,
                                                    startTime,
                                                    index: period.index,
                                                    available:
                                                        period.available -
                                                        ((result && result.data.appointmentsPerPeriod?.[period.index]) || 0),
                                                });
                                            }

                                        } else if (isBefore(dateNow, startTime) && alloWDayFilter && blockOutDaysValidation && blockLocation) {

                                            if (testSelection !== 'flight' && (isAfter(parsed, new Date(startDate)) || isEqual(parsed, new Date(startDate)))) {

                                                a.push({
                                                    ...period,
                                                    startTime,
                                                    index: period.index,
                                                    available:
                                                        period.available -
                                                        ((result && result.data.appointmentsPerPeriod?.[period.index]) || 0),
                                                });
                                            }
                                        } else {
                                            return a;
                                        }
                                    }
                                    return a;
                                }, [])
                            )
                    );

                    await new Promise<void>((resolve) => {
                        setTimeout(() => {
                            resolve();
                        }, 800);
                    });
                }
                setTimeout(()=>{
                    setLoading(false);
                },2000)
            } catch (e) {
                console.error(e);

                setLoading(false);
            }
        }
        setSelectedDate(date ? parse(date, config.dateFormat, new Date()) : new Date())

        new Promise<void>((resolve) => {
            setTimeout(() => {
                getSlots();
            }, 1000);
        });

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [date, departureDateAndTime, isExpressSameDayTest, location, locationId, isRapidTest, isAntigen, rescheduleslots]);
    // blocking next day after some time period
    let bufferDate:any[]=[]
    let currentTime = new Date();
    let Hour = currentTime.getHours()
    let nextDay = format(addDays(currentTime,1),config.dateFormat).toString()
    let blockOutDayTime:any = location?.blockNextDayAfter?location?.blockNextDayAfter?.replace(/[^0-9]/g, ''):''
    if(blockOutDayTime!==''&&location?.blockNextDayAfter?.endsWith('p'))
    {
        blockOutDayTime = parseInt(blockOutDayTime)+12;        
        if(Hour>=blockOutDayTime)
            if(!(location?.blockOutDays!==undefined&&location?.blockOutDays?.length!==0&&location?.blockOutDays.includes(nextDay)))
            {
                location?.blockOutDays.push(nextDay)
                let bufferdate=[];
                bufferdate.push(nextDay)
                bufferDate=bufferdate;
            }
    }
    else if(blockOutDayTime!==''&&location?.blockNextDayAfter?.endsWith('a'))
    {
        blockOutDayTime = parseInt(blockOutDayTime);
        if(Hour>=blockOutDayTime)
            if(!(location?.blockOutDays!==undefined&&location?.blockOutDays?.length!==0&&location?.blockOutDays.includes(nextDay)))
            {
                location?.blockOutDays.push(nextDay)
                let bufferdate=[];
                bufferdate.push(nextDay)
                bufferDate=bufferdate;
            }
    }
    else
    {
        bufferDate=[];
    }
    // ends blocking next day
    return (
        <Field name="slot">
            {({
                form: { setFieldValue, setFieldError, values },
                field: { value },
                meta: { error },
            }: FieldProps) => {
                const parsedDeparture = departureDateAndTime
                    ? parse(departureDateAndTime, config.dateTimeFormat, new Date())
                    : new Date();

                return (
                    <Container>
                        <Header>
                            <SelectedDate>
                                {format(selectedDate, config.weekDateFormat)}
                            </SelectedDate>
                            <DatePickerContainer alwaysShowDatePicker={alwaysShowDatePicker}>
                                <Datepicker
                                    value={selectedDate}
                                    maxDate={getMaxAvailableDate(
                                        location,
                                        isExpressSameDayTest,
                                        parsedDeparture,
                                        isRapidTest
                                    )}
                                    minDate={getMinAvailableDate(
                                        location,
                                        isExpressSameDayTest,
                                        parsedDeparture,
                                        isRapidTest
                                    )}
                                    onChange={(newDepartureDate) => {

                                        setSelectedDate(newDepartureDate)
                                        if (onChangeDate) {
                                            onChangeDate(newDepartureDate)
                                        }
                                    }}
                                    customInput={<ChangeDateBtn>Change date</ChangeDateBtn>}
                                />
                            </DatePickerContainer>
                        </Header>
                        <SlotsContainer>
                            {!loading ? slots.length > 0 ? (
                                slots.map(({ label, startTime, available, index }: { label: any, startTime: any, available: any, index: any }, i: any) => {
                                    const preSelectedSlotDate = slot?.date
                                        ? parse(slot.date, config.dateFormat, new Date())
                                        : null;

                                    const isSelected =
                                        !!preSelectedSlotDate &&
                                        isEqual(selectedDate, preSelectedSlotDate) &&
                                        locationId === slot?.locationId &&
                                        index === value?.period;


                                    return (
                                        <Slot key={i}>
                                            <ChooseSlotBtn
                                                selected={isSelected}
                                                type="button"
                                                onClick={ async() => {

                                                    if (isSelected || !location) {
                                                        return;
                                                    }
                                                    const desiredSlot = {
                                                        date: format(selectedDate, config.dateFormat),
                                                        period: index,
                                                        locationId: location?.qbenchCustomerId,
                                                        label: label
                                                    };
                                                    localStorage.setItem("desiredSlot", JSON.stringify(desiredSlot))

                                                    appointment.slot = desiredSlot;

                                                    onFieldUpdate({ slot: desiredSlot });
                                                    setFieldValue('slot', desiredSlot);

                                                    setLoading(true);
                                                    await reserveSlot(
                                                        desiredSlot,
                                                        rescheduleReservationId !== "" && rescheduleReservationId !== null && rescheduleReservationId !== undefined ? 
                                                            slot
                                                            : null,
                                                        numberOfGuests,
                                                        isRapidTest,
                                                        rescheduleReservationId,
                                                        isManage,
                                                        appointment
                                                        ).then((res)=>{
                                                            if(res.data.status == 200){
                                                                onFieldUpdate({
                                                                    rescheduleReservationId: res.data.reservationId
                                                                })
                                                                setLoading(false);
                                                            }
                                                            setLoading(false);
                                                        }).catch((e) => { 
                                                            // setFieldError('slot', e.message) 
                                                        });
                                                }}
                                                disabled={
                                                    available === 0 ||
                                                    available < numberOfGuests ||
                                                    loading 
                                                }
                                            >
                                                {label}
                                            </ChooseSlotBtn>
                                            {loading ? (
                                                <Shimmer />
                                            ) : (
                                                <span>{pluralize(available, 'slot')} available</span>
                                            )}
                                        </Slot>
                                    );
                                })
                            ) : (
                                <>No available slots</>
                            ): <Shimmer />}
                        </SlotsContainer>
                        <Error>
                            <ErrorText hasError={error !== undefined}>{error}</ErrorText>
                        </Error>
                    </Container>
                );
            }}
        </Field>
    );
};

export default Slots;

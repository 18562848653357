import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { useStepsState, useStepsDispatch } from '../../shared/providers/schedule/provider';
import { Button } from './styled';
import { colors } from '../../../styles/colors';
import { Breakpoints } from '../../../dictionaries';
import question from "../../../assets/question.png"
import Modal from './Modal';
import LocationButtonGroup from './form/LocationButtonGroup';
import { cloneDeep } from 'lodash';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;
//margin: 0 -5px;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  float: right;
  margin: 10px 0px 10px 0px;

  & > * {
    margin: 0 5px;
  }
`;

//font-size: 28px;
const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 25px;
`;

export const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 1px;
`;


export const QuestionIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${question}) no-repeat center center;
`;

interface Props {
  confirmAndPay?: boolean;
  title?: string;
  content?: string;
  onConfirm?: (data?: any) => void;
  onCancel?: (data?: any) => void;
}

const AlertModal: React.FC<Props> = ({ title, content, onConfirm, onCancel }) => {

  const {
    form: { location },
    showAlertModal
  } = useStepsState();
  const { toggleShowAlertModal } = useStepsDispatch();
  const closeModal = () => toggleShowAlertModal(false);

  return (
    <Modal open={showAlertModal} onClose={closeModal} closeOnOverlayClick={false} closeOnEsc={false}>
      <Formik
        initialValues={{ location }}
        onSubmit={() => {
          if (onConfirm) {
            onConfirm();
          }
        }}
      >
        {() => (
          <Form>
            <Content>
              <div style={{ display: 'flex' }} >
                <img src={question} style={{margin: "0px 10px 0px 0px", width: "25px", height:"25px"}} />
                  <div>
                    {title && <Title>{title}</Title>}
                    <div style={{ margin: "10px 0px 10px 0px" }} >
                      {content && <div>{content}</div>}
                    </div>
                    <ButtonsContainer>
                      <Button
                        libraryType="default"
                        size="sm"
                        type="button"
                        onClick={() => {
                          if (onCancel) {
                            onCancel()
                          }
                          closeModal();
                        }}
                      >
                        Cancel
                    </Button>
                      <Button libraryType="primary" size="sm" type="submit">
                        Confirm
                    </Button>
                    </ButtonsContainer>
                  </div>
              </div>
            </Content>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AlertModal;

import React, { useState, useMemo } from 'react';

import { useManageDispatch, useManageState } from '../provider';
import { rescheduleAppointment } from '../api';

import Schedule from '../../shared/components/Schedule';
import { ManagePage } from '../dictionaries';
import { useStepsDispatch } from '../../shared/providers/schedule/provider';
import { updateMangeReservation } from '../../shared/providers/schedule/api';
import ReschedulePaymentModal from './ReschedulePaymentModal';
import { cloneDeep } from 'lodash';
import AlertModal from '../../shared/components/AlertModal';
import DownGradeModal from './DownGradeModal';


const Reschedule: React.FC = () => {
	const { appointment, showLocation, apptPrices, showStandradTest } = useManageState();
	const { toggleChangeLocationModal } = useStepsDispatch();
	const { updateAppointment, goToPage, toggleLocation } = useManageDispatch();
	const [loading, setLoading] = useState<boolean>();
	const [isEmployeeEmail, setIsEmployeeEmail] = useState(false);
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [showModal, setShowModal] = useState(false);

    const [checkappointment, setcheckappointment] = useState<{
        change: boolean;
        upgrade: boolean;
        previous: any;
        present: any;
    }>({ change: false, upgrade: false, previous: '', present: '' });
    
	const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(appointment?.isExpressSameDayTest), [true]);
	const rollbackisRapidTest = useMemo(() => cloneDeep(appointment?.isRapidTest), [true]);
    const rollbackisAntigenTest = useMemo(
        () => cloneDeep(appointment?.isAntigen),
        [true]
    );
	if (!appointment) {
		return null;
	}

    const checkEveryTime = () => {
        let oldTest: any = rollbackisExpressSameDayTest === true ? 2 : rollbackisRapidTest === true ? 3 : rollbackisAntigenTest === true ? 0 : 1;
        let currentTest: any = appointment?.isExpressSameDayTest === true ? 2 : appointment.isRapidTest === true ? 3 : appointment?.isAntigen === true ? 0 : 1;
        if (oldTest === currentTest) {
            setcheckappointment((previousstate) => {
                return {
                    change: false,
                    upgrade: false,
                    previous: '',
                    present: ''
                }
            })
        }
        else {
            let previous = oldTest === 3 ? 'Rapid' : oldTest === 2 ? 'Express' : oldTest === 0 ? 'Antigen' : 'Standard'
            let present = currentTest === 3 ? 'Rapid' : currentTest === 2 ? 'Express' : currentTest === 0 ? 'Antigen' : 'Standard'
            if (oldTest > currentTest)
                setcheckappointment((e) => { return { change: true, upgrade: false, previous, present } })
            else
                setcheckappointment((e) => { return { change: true, upgrade: true, previous, present } })
        }
    }
	const resheduleAppointmentHandler = async () => {

		let oldTest: any =
            rollbackisExpressSameDayTest == true
                ? 2
                : rollbackisRapidTest == true
                    ? 3
                    : rollbackisAntigenTest ? 0 : 1;
        let currentTest =
            appointment?.isExpressSameDayTest == true
                ? 2
                : appointment.isRapidTest == true
                    ? 3
                    : rollbackisAntigenTest ? 0 : 1;

		setLoading(true);
		try {
			if(appointment?.hasInsurance !== undefined && appointment?.hasInsurance || !!isEmployeeEmail){

                let appointmentData: any = appointment;
                delete appointmentData?.qbenchAcknowledgement;
                if (appointmentData?.rescheduleReservationId !== undefined) {
                    updateMangeReservation(
                        appointment.reservationId,
                        appointment.rescheduleReservationId
                    );
                    appointmentData.reservationId =
                        appointment.rescheduleReservationId;
                    delete appointmentData?.rescheduleReservationId;
                }

                if (appointmentData?.qbenchId) {
                    await rescheduleAppointment(appointmentData);
                    goToPage(ManagePage.RescheduleConfirm);
                }

            } else if (oldTest > currentTest) {
				setShowModal(true);
			} else if (oldTest < currentTest) {
				setShowPaymentModal(true);
			} else {
				let appointmentData: any = appointment
				// delete appointmentData?.samples
				if (appointmentData?.rescheduleReservationId !== undefined) {
					updateMangeReservation(appointment.reservationId, appointment.rescheduleReservationId);
					appointmentData.reservationId = appointment.rescheduleReservationId
					delete appointmentData?.rescheduleReservationId
				}
				delete appointmentData?.qbenchAcknowledgement;
				await rescheduleAppointment(appointmentData);
				goToPage(ManagePage.RescheduleConfirm);
			}	
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<>
			<Schedule
				isManage={true}
				title="Reschedule Your Appointment: Pick a date and time"
				prices={apptPrices}
				standardTest={showStandradTest}
				onLocationChange={() => {
					toggleLocation(true);
				}}
				onSchedule={async () => resheduleAppointmentHandler()}
				onFieldUpdate={(update) =>
                {
                    checkEveryTime();
                    updateAppointment({ ...appointment, ...update })
                }
				}
				appointment={appointment}
				confirmBtnText="Confirm Reschedule"
                loading={loading}
                RescheduleTestType={checkappointment}
				setIsEmployeeEmail={setIsEmployeeEmail}
                isEmployeeEmail={isEmployeeEmail}
			/>
			<ReschedulePaymentModal
				showModal={showPaymentModal}
				closeModal={() => {
					setLoading(false);
					setShowPaymentModal(false);
				}}
			/>
			<DownGradeModal
				showModal={showModal}
				onCancel={() => {
					setShowModal(false);
					setLoading(false);
				}}
				title={"Note"}
				content={"Please call or email customer service at 213-948-2461 or support@worksitelabs.com to request for a refund for your downgrade to a Standard Test"}
				onConfirm={async () => {
					setShowModal(false);
					let appointmentData: any = appointment
					// delete appointmentData?.samples
					delete appointmentData?.qbenchAcknowledgement;
					if (appointmentData?.rescheduleReservationId !== undefined) {
						updateMangeReservation(appointment.reservationId, appointment.rescheduleReservationId);
						appointmentData.reservationId = appointment.rescheduleReservationId
						delete appointmentData?.rescheduleReservationId
					}
					await rescheduleAppointment(appointmentData);
					goToPage(ManagePage.RescheduleConfirm);
				}}
			/>
		</>
	);
};

export default Reschedule;

import { format, isAfter, isBefore, parse } from 'date-fns';
import { config } from '../../config';
import { parseSnapshot } from '../../utils';
import { firebase } from '../../firebase';
import { Appointment } from '../shared/models';
import _, { filter, sample, chain } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { ClickAwayListener } from '@material-ui/core';

export const getAppointmentByPhoneAndDate = (phone: string, date: Date) =>
  firebase
    .firestore()
    .collection(config.firestoreCollections.appointments)
    .where('phone', '==', phone.replace(/[^0-9]+/g, ''))
    .where('birthDate', '==', format(date, config.dateFormat))
    .get()
    // .then(parseSnapshot);
    .then((snapshot) => {
      let appointmentList: any = [];
      snapshot.docs
        .map((doc) => doc.data() as Appointment)
        .map((loc: any) => {
          appointmentList.push({
            ...loc,
          });
        });

      let appointment: any = _.orderBy(appointmentList, ['qbenchId'], ['desc']);
      if (appointment.length > 0) {
        return {
          id: appointment[0].id,
          data: appointment[0],
        };
      } else {
        return null;
      }
    });

export const getAppointmentByConfirmationId = (confirmationId: string) =>
  firebase
    .firestore()
    .collection(config.firestoreCollections.appointments)
    .where('confirmationId', '==', confirmationId)
    .get()
    .then(parseSnapshot);

export const getAppointmentByPhoneorEmail = async (
  phone: string,
  email: string,
  minor:boolean|null,
  isSchedularLite:any
) => {
  return firebase
    .functions()
    .httpsCallable('getQbenchOrderById')({ phone, email, minor, isSchedularLite })
    .then( async (res) => {
      let appointment: any = [];
      let FinalData: any = [];

      if (res.data) {
        appointment = _.orderBy(
          res.data.appointments,
          ['date'],
          ['desc']
        );
        let consolidatedList: any = [];

        appointment.map((response: any, index: any) => {
          if (response.qbenchAcknowledgement.length > 0) {
            let numberOfSamples: any = [];
            response.qbenchAcknowledgement.map(
              (ackRespone: any, ackIndex: any) => {
                if (
                  isAfter(
                    parse(response?.date, config.dateFormat, new Date()),
                    new Date()
                  ) &&
                  ackRespone.results == '' &&
                  ackRespone.inprogress.length <= 0
                ) {
                  numberOfSamples.push(config.manageScreen.upcoming);
                } else if (
                  (isBefore(
                    parse(response?.date, config.dateFormat, new Date()),
                    new Date()
                  ) ||
                    isAfter(
                      parse(response?.date, config.dateFormat, new Date()),
                      new Date()
                    )) &&
                  // (
                    ackRespone.results == '' 
                  // ||
                  // ackRespone.inprogress.length > 0)
                ) {
                  numberOfSamples.push(config.manageScreen.inProcess);
                } else if (
                  (isBefore(
                    parse(response?.date, config.dateFormat, new Date()),
                    new Date()
                  ) ||
                    isAfter(
                      parse(response?.date, config.dateFormat, new Date()),
                      new Date()
                    )) &&
                  ackRespone.results !== '' &&
                  ackRespone.inprogress.length <= 0
                ) {
                  numberOfSamples.push(config.manageScreen.completed);
                }
              }
            );

            let compare = numberOfSamples.every(
              (val: any, i: any, arr: any) => val === arr[0]
            );
            if (compare && numberOfSamples?.[0] == config.manageScreen.missed) {
              consolidatedList.push({
                ...response,
                orderById: config.manageScreen.missed,
              });
            } else if (
              compare &&
              numberOfSamples?.[0] == config.manageScreen.completed
            ) {
              consolidatedList.push({
                ...response,
                orderById: config.manageScreen.completed,
              });
            } else if (
              compare &&
              numberOfSamples?.[0] == config.manageScreen.upcoming
            ) {
              consolidatedList.push({
                ...response,
                orderById: config.manageScreen.upcoming,
              });
            } else {
              consolidatedList.push({
                ...response,
                orderById: config.manageScreen.inProcess,
              });
            }
          }
        });

        appointment = _.orderBy(consolidatedList, ['date'], ['desc']);
        appointment = _.orderBy(appointment, ['orderById'], ['desc']);

        FinalData = await chain(appointment)
          // Group the elements of Array based on `color` property
          .groupBy('firstName')
          // `key` is group's name (color), `value` is the array of objects
          .map((value, key) => ({ firstName: key, appointments: value }))
          .value();
      }

      if (FinalData.length > 0) {
        return FinalData;
      } else {
        return [];
      }
    });
};

export const getAppointmentByConfirmationIdPhoneAndDate = (
  confirmationId: string,
  phone: string,
  date: Date
) =>
  firebase
    .firestore()
    .collection(config.firestoreCollections.appointments)
    .where('confirmationId', '==', confirmationId)
    .where('phone', '==', phone.replace(/[^0-9]+/g, ''))
    .where('birthDate', '==', format(date, config.dateFormat))
    .get()
    .then(parseSnapshot);

export const getQbenchOrderById = async (id: string) =>
  firebase.functions().httpsCallable('getQbenchOrderById')(id);

export const rescheduleAppointment = async (appointment: Appointment) =>
  firebase.functions().httpsCallable('rescheduleAppointment')(appointment);

export const initiateOTP = async (phone: string, email: string) => {
  if (email) {
    return firebase.functions().httpsCallable('initiateOTP')({
      email: email,
      phone: null,
      isUserOTP:true,
      validationMethod:'email',
      isManage: true
    });
  } else if (phone) {
    return firebase.functions().httpsCallable('initiateOTP')({
        email: null,
        phone: phone,
        isUserOTP: true,
        validationMethod: "phone",
        isManage: true
    });
  }
};

export const validateOTP = async (
  otp: string,
  phone?: string,
  email?: string
) => {
  if (otp) {
    if (phone) {
      return firebase
        .firestore()
        .collection(config.firestoreCollections.onetimepassword)
        .where('phone', '==', phone)
        .where('otp', '==', otp)
        .where('isActive', '==', true)
        .get()
        .then((snapshot) => {
          let onetimepassword: any = [];
          snapshot.forEach(function (doc) {
            doc.ref.update({
              isActive: false,
            });
          });
          snapshot.docs
            .map((doc) => doc.data())
            .map((loc: any) => {
              if (
                moment(loc.expiresIn.toDate()).format('hh:mm') >
                moment(new Date()).format('hh:mm')
              ) {
                onetimepassword.push({
                  ...loc,
                });
              }
            });
          if (onetimepassword.length > 0) {
            return true;
          }
        });
    } else {
      return firebase
        .firestore()
        .collection(config.firestoreCollections.onetimepassword)
        .where('email', '==', email)
        .where('otp', '==', otp)
        .where('isActive', '==', true)
        .get()
        .then((snapshot) => {
          let onetimepassword: any = [];
          snapshot.forEach(function (doc) {
            doc.ref.update({
              isActive: false,
            });
          });
          snapshot.docs
            .map((doc) => doc.data())
            .map((loc: any) => {
              if (
                moment(loc.expiresIn.toDate()).format(
                  'MMMM Do YYYY, h:mm:ss a'
                ) > moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')
              ) {
                onetimepassword.push({
                  ...loc,
                });
              }
            });
          if (onetimepassword.length > 0) {
            return true;
          }
        });
    }
  }
};

export const getStripeRefund = async (params: {
  paymentIntentId: any;
  reason: any;
}) => firebase.functions().httpsCallable('stripRefund')(params);

export const addStripeRefundCollection = (params: {
  confirmationId: any;
  paymentIntentId: any;
  reason: any;
  createddate:any;
  stripeObject: any;
}) =>
  firebase
    .firestore()
    .collection(config.firestoreCollections.reschedulerefunds)
    .add(params);

    export const getNewSlots = async (params: any) =>
firebase.functions().httpsCallable('getNewSlots', { timeout: 540 * 1000 })(params);

import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Button,
  Container,
  Content,
  PageHeader,
  PageTitle,
} from '../../shared/components/styled';
import { IoPersonOutline } from 'react-icons/io5';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useManageDispatch, useManageState } from '../provider';
import { Breakpoints } from '../../../dictionaries';
import { ManagePage } from '../dictionaries';
import {
  cancelAppointment,
  updatePayment,
} from '../../shared/providers/schedule/api';
import { colors } from '../../../styles/colors';

const CancelBtn = styled(Button)`
  width: 100%;

  @media (min-width: ${Breakpoints.sm}px) {
    width: 450px;
  }
`;

const Text = styled.p`
  margin-bottom: 45px;
`;

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: left;
`;
export const Input:any = styled.input`
  height: 19px;
  width: 19px;
  border: 1px solid ${colors.renderBlue};
  border-radius: 0px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
  background-color: ${colors.renderBlue};
`;

const Flexbox = styled.div`
  display: flex;
  align-items: center;
  margin-top: -23px;
  width: 100%;
  &:nth-child(1) {
    width: max-content;
    margin-right: 5px;
  }
  &:nth-child(2) {
    border: none;

    margin-right: 0px;
    padding-right: 0px;
  }
  &:nth-child(3) {
    margin-left: 50px;
  }
  &:nth-child(4) {
    margin-left: 50px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    &:nth-child(1),
    &:nth-child(2) {
      width: 100%;
      border-right: none;
    }
    &:nth-child(4) {
      margin-left: 0px;
      border-right: none;
    }
    &:nth-child(3) {
      width: 100%;
      margin-left: 0px;
      border-right: none;
    }
  }
`;
const Buttondiv = styled.div`
  width: 49%;
  min-width: 200px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 100%;
  }
`;
const FlexboxIcon = styled.div`
  position: relative;
  color: ${colors.grey};
  display: inline-flex;
`;
const FlexboxText = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding-left: 0px;
  word-break: break-all;
`;

const Cancel: React.FC = () => {
  const { appointment } = useManageState();
  const { goToPage } = useManageDispatch();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [partChecked, setPartChecked] = useState(false);
  const getallData = () => {
    let index = [];
    if (appointment?.registeringFor === 'myself') {
      index.push(-1);
    } else if (appointment?.registeringFor === 'myselfAndMinor') {
      index.push(-1);
      appointment?.minors.map((minorindex: any, i: number) => {
        index.push(i);
      });
    } else if (appointment?.registeringFor === 'minorOnly') {
      appointment?.minors.map((minorindex: any, i: number) => {
        index.push(i);
      });
    }
    return index;
  };
  const [param, setParam] = useState(getallData());
  console.log('appointment', appointment);

  const cancelAppointmentHandler = async () => {
    setLoading(true);
    setError(null);
    try {
      if (appointment?.qbenchId) {
        let params: any = { id: appointment.id };
        if (param.length === getallData().length) {
          console.log('ok param', param);
          setParam([]);
          params['index'] = [];
        } else {
          params['index'] = param;
        }

        if (
          appointment.reservationId == undefined &&
          appointment.reservationId !== '' &&
          appointment.reservationId !== null
        ) {
          updatePayment(appointment.reservationId, 2);
        }
        await cancelAppointment(params);
        goToPage(ManagePage.CancelConfirm);
      } else {
        // @ts-ignore
        throw new Error('Appointment data is incomplete');
      }
    } catch (e) {
      setLoading(false);
      setError(e.message);
      console.error(e);
    }
  };

  const spliceIndex = (type: boolean, method: any) => {
    let array: any[] = param;
    if (type === true) {
      array.splice(1, 0, method);
    } else if (type === false) {
      let splice: any;
      if (array.includes(method)) {
        splice = array.indexOf(method);
      }
      array.splice(splice, 1);
    }
    return array;
  };

  return (
    <Container size="md">
      <Content>
        <PageHeader>
          <PageTitle>
            Are you sure you want to cancel your appointment?
          </PageTitle>
        </PageHeader>

        {getallData().length > 1 ? (
          <DialogContent>
            <DialogContentText>
              <Text>
                If you’d like to cancel your appointment, please select and
                click on the link below.
              </Text>
            </DialogContentText>
            <div style={{ display: 'flex' }}>
              <Input
                style={{ accentColor: '#243D4D' }}
                defaultChecked={checked}
                onChange={(value: any) => {
                  console.log('value', value.target.checked);
                  setPartChecked(!value.target.checked);
                  setChecked(value.target.checked);
                  if (!value.target.checked) {
                    setParam([]);
                  } else {
                    setParam(getallData());
                  }
                }}
                type="checkbox"
              />
              <Text>click here to cancel complete appointment</Text>
            </div>
            {(appointment?.registeringFor === 'myself' ||
              appointment?.registeringFor === 'myselfAndMinor') && (
              <div style={{ display: 'flex' }}>
                <Input
                  style={{ accentColor: '#243D4D' }}
                  disabled={checked}
                  type="checkbox"
                  onChange={(value:any) => {
                    console.log('value.target.checked', value.target.checked);
                    setParam(spliceIndex(value.target.checked, -1));
                  }}
                />
                &nbsp; &nbsp;
                <Flexbox>
                  <FlexboxIcon>
                    <IoPersonOutline size={22} />
                  </FlexboxIcon>
                  <FlexboxText>
                    <b>{'Name : '}</b>
                    {appointment?.firstName} {appointment?.middleName}{' '}
                    {appointment?.lastName}
                  </FlexboxText>
                </Flexbox>
              </div>
            )}
            <br />
            {appointment?.minors.length &&
              appointment?.minors
                .filter((minor: any) => !minor.isCanceled)
                .map((minorindex: any, i: number) => {
                  console.log('minorindex', minorindex);
                  return (
                    <>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <Flexbox>
                          <Input
                            style={{ accentColor: '#243D4D' }}
                            disabled={checked}
                            onChange={(value: any) => {
                              console.log(
                                'value.target.checked',
                                value.target.checked,
                                i
                              );
                              setParam(spliceIndex(value.target.checked, i));
                            }}
                            type="checkbox"
                          />{' '}
                          &nbsp; &nbsp;
                        </Flexbox>
                        <Flexbox>
                          <FlexboxIcon>
                            <IoPersonOutline size={22} />
                          </FlexboxIcon>
                          <FlexboxText>
                            <b>{'Name : '}</b>
                            {minorindex?.firstName} {minorindex?.middleName}{' '}
                            {minorindex?.lastName}
                          </FlexboxText>
                        </Flexbox>
                      </div>
                      <br />
                    </>
                  );
                })}
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText>
              <Text>
                If you’d like to cancel your appointment, please click Cancel Appointment button
              </Text>
            </DialogContentText>
          </DialogContent>
        )}

        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between', gap:5,width:'100%'}}>
            <Buttondiv>
                <Button
                    libraryType="default"
                    type="button"
                    disabled={loading}
                    fullWidth={true}
                    onClick={() =>
                        {
                            goToPage(ManagePage.TestResults)
                        }
                    }
                >
                    Return
                </Button>
            </Buttondiv>
            <Buttondiv>
                <Button
                    disabled={loading}
                    libraryType="primary"
                    type="button"
                    fullWidth={true}
                    onClick={() =>
                        {
                            cancelAppointmentHandler();
                        }
                    }
                >
                    {loading ? 'Cancelling...' : 'Cancel Appointment'}
                </Button>
            </Buttondiv>
        </div>
        {error && <Error>{error}</Error>}
      </Content>
    </Container>
  );
};

export default Cancel;

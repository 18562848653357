import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
	Container,
	Content,
	PageHeader,
	PageTitle,
} from '../../shared/components/styled';
import {
	CalendarIcon,
	ClockIcon,
	PinIcon,
	PersonIcon,
	TestingInfo,
	TestingInfoContainer,
	TestingInfoRow,
} from '../components/ConfirmAndPay/ConfirmAndPay';
import UpArrow from "../../../assets/up.png"
import DownArrow from "../../../assets/down.png"
import { useManageState, useManageDispatch } from '../provider';
import { colors } from '../../../styles/colors';
import { Button } from '../../shared/components/styled';
import { Breakpoints } from '../../../dictionaries';
import { downloadResultsPDF } from '../../shared/providers/admin/api';
import { config } from '../../../config';
import { getPeriodsFromSchedule, slotsListPeriod } from '../../../utils';
import { format, isAfter, isBefore, parse } from 'date-fns';
import { ManagePage } from '../dictionaries';
import { toLower } from 'lodash';
// import { getSlotsByLocationId } from '../../shared/api';
import circleRectAngleBottomBlack from '../../../assets/circle-rectangle-dark-blue-bottom.png';
import { Grid } from '@material-ui/core';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const periods = getPeriodsFromSchedule(config.schedule);
const rapidPeriods = getPeriodsFromSchedule(config.rapidSchedule);


const ResultValue = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-transform: Capitalize;
  color: ${({ value }: { value: string }) =>
    value === 'POSITIVE' ? colors.red : colors.green};
`;

const Background = styled.div`
  width: 100vw;
  z-index: 10;
  background-repeat: no-repeat;
  height: 8vh;
  @media (max-width: ${Breakpoints.md}px) {
    background-position: bottom;
    background-image: url(${circleRectAngleBottomBlack});
    background-size: 100% 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    background-size: 100% 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;
const TestingInfoWrapper = styled.div`
  margin: 40px 0 45px;
`;

const Navigation = styled.div`
  display: flex;
  margin: 0 -6px 50px 0px;

  & > * {
    margin: 0 6px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: none;
  }
`;
const NavigationSM = styled.div`
  display: none;
  margin: 0 -6px 50px 0px;

  & > * {
    margin: 0 6px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: flex;
  }
`;

interface ListContainerProps {
  selected: any;
  index: any;
}

interface ButtonProps {
  selected: boolean;
}

const Appointmentdetails = styled.p`
  width: fit-content;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  @media (max-width: ${Breakpoints.md}px) {
    width: 100%;
    text-align: center;
  }
`;
const UserDetailsSection = styled.div`
  text-align: right;
  @media (max-width: ${Breakpoints.md}px) {
    text-align: left;
  }
`;
interface alignItems {
  alignItems?: string;
}
const Maincontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: ${Breakpoints.sm}px) {
    align-items: ${({ alignItems }: alignItems) =>
      alignItems ? alignItems : 'flex-start'};
  }
`;
interface Text {
  text?: string;
  color?: string;
}
const Contentheader = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ color }: Text) => (color ? color : '#565656')};
  @media (max-width: ${Breakpoints.sm}px) {
    text-align: ${({ text }: Text) => (text ? text : 'left')};
  }
`;
const Contentbody = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ color }: Text) => (color ? color : '#000000')};
  @media (max-width: ${Breakpoints.sm}px) {
    text-align: ${({ text }: Text) => (text ? text : 'left')};
  }
`;
const Subcontainer = styled.div`
  padding: 5px 20px;
  width: 100%;
  min-height: 90px;
  background: rgb(255, 255, 255);
  border-radius: 6px;
  margin-bottom: 25px;
  @media (max-width: 1280px) {
    padding: 20px 20px;
  }
`;
const Desktopbutton = styled.div`
  @media (max-width: ${Breakpoints.md}px) {
    display: none;
  }
`;
const Mobilebutton = styled.div`
  display: none;
  @media (max-width: ${Breakpoints.md}px) {
    display: block;
  }
`;

function normalizeResultName(result: string) {
	const res = result.toLowerCase();

	if (res === 'detected') {
		return 'POSITIVE';
	} else if (res === 'not detected') {
		return 'NEGATIVE';	
	}

	return 'INDETERMINATE';
}

function checkAppointmentStatus(params: any,IsMinorAccount:any,minorEmail:any, clickedBox?:boolean) {
	let result:boolean=false;
	if(IsMinorAccount)
	{
		params?.qbenchAcknowledgement.map((e:any)=>{
			if(e?.minorIndex!==-1)
			{
				let minors = e?.minors[e?.minorIndex]
				if(toLower(minors?.email)===toLower(minorEmail) && e?.results!=='')
				{
					result=true;
				}
			}
		})
		if(result)
		{
			return(	<>
						<Maincontent>
                            <Contentheader color={clickedBox ? '#E0E0E0' : '#565656'}>Status</Contentheader>
                            <Contentbody color={clickedBox ? '#FFFFFF' : '#000000'}>Completed</Contentbody>
                        </Maincontent>
					</>
					)
		}
		else
		{
			return(<>
						<Maincontent>
                            <Contentheader color={clickedBox ? '#E0E0E0' : '#565656'}>Status</Contentheader>
                            <Contentbody color={clickedBox ? '#FFFFFF' : '#000000'}>Upcoming</Contentbody>
                        </Maincontent>
					</>
				)
		}
	}
	else
	if (params.orderById == 3) {
		return (
			<>
				<Maincontent>
                    <Contentheader color={clickedBox ? '#E0E0E0' : '#565656'}>Status</Contentheader>
                    <Contentbody color={clickedBox ? '#FFFFFF' : '#000000'}>Completed</Contentbody>
                </Maincontent>
			</>
		)
	} else if (params.orderById == 5) {
		return (
			<>
				<Maincontent>
                    <Contentheader color={clickedBox ? '#E0E0E0' : '#565656'}>Status</Contentheader>
                    <Contentbody color={clickedBox ? '#FFFFFF' : '#000000'}>Upcoming</Contentbody>
                </Maincontent>
			</>
		)
	} else if (params.orderById == 4) {
		return (
			<>
				<Maincontent>
                    <Contentheader color={clickedBox ? '#E0E0E0' : '#565656'}>Status</Contentheader>
                    <Contentbody color={clickedBox ? '#FFFFFF' : '#000000'}>In Progress</Contentbody>
                </Maincontent>
			</>
		)
	}
}


const TestResults: React.FC = () => {
	const { appointmentList,IsMinorAccount,minorEmail } = useManageState();
	const [saving, setSaving] = useState(false);
    const clickedBox = (index:number, aIndex:number)=>(hide.aIndex === aIndex && hide.index === index)

	const { goToPage, updateAppointment } = useManageDispatch();
	const [hide, setHide] = useState({ index: -1, aIndex: -1 });

	return (
        <>
		<Container size="xxl">
			<Content>
				{(appointmentList || []).map((appt: any, index: any) => {
					return (
						<div key={index}>
							{IsMinorAccount?'':(
								<div style={{width:'100%', display:'flex', justifyContent:'space-between', flexWrap:'wrap',marginBottom:30, gap:10}}>
                                        <Appointmentdetails>See Appointment Details</Appointmentdetails>
                                        <UserDetailsSection>
                                            <p style={{fontWeight:700, fontSize:28 }}>{appt.firstName} {appt.appointments[0]!.lastName}</p>
                                            <p style={{fontWeight:600, fontSize:18, lineHeight:'134%'}}>
                                            Email: {appt.appointments[0]!.email}
                                            </p>
                                            <p style={{fontWeight:600, fontSize:18, lineHeight:'134%'}}>
                                            Phone: {appt.appointments[0]!.phone}
                                            </p>
                                        </UserDetailsSection>
                                    </div>
							)}
							{
								appt.appointments.map((apptData: any, aIndex: any) => {

									return (
										<>
											<>
                                                <Subcontainer key={aIndex} onClick={() => {setHide({ index: index, aIndex: hide.aIndex == aIndex ? -1 : aIndex })}} style={{boxShadow:`${clickedBox(index, aIndex) ? 'none' : '-4px -4px 8px rgba(153, 153, 153, 0.15), 4px 4px 8px rgba(0, 0, 0, 0.3)'}`, background: `${clickedBox(index, aIndex) ? '#243D4D':'white'}`, color:`${clickedBox(index, aIndex) ? 'white':'black'}`}}>
                                                    <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between', width:'100%', minHeight:90, cursor:'pointer', alignItems:'center'}}>
                                                        <Grid
                                                            style={{padding:'0px 10px'}}
                                                            container
                                                            spacing={4}
                                                            alignItems="center"
                                                            justify='space-evenly'
                                                        >
                                                            <Grid item xs={6} lg={2} md={4} sm={6}>
                                                                <Maincontent>
                                                                    <Contentheader color={clickedBox(index,aIndex) ? '#E0E0E0' : '#565656'}>Confirmation Number:</Contentheader>
                                                                    <Contentbody color={clickedBox(index,aIndex) ? '#FFFFFF' : '#000000'}>{apptData.confirmationId}</Contentbody>
                                                                </Maincontent>
                                                            </Grid>
                                                            <Grid item xs={6} lg={2} md={4} sm={6}>
                                                                <Maincontent alignItems='flex-end'>
                                                                    <Contentheader text='right' color={clickedBox(index,aIndex) ? '#E0E0E0' : '#565656'}>Appointment Date</Contentheader>
                                                                    <Contentbody text='right' color={clickedBox(index,aIndex) ? '#FFFFFF' : '#000000'}>{apptData.date}</Contentbody>
                                                                </Maincontent>
                                                            </Grid>
                                                            <Grid item xs={6} lg={2} md={4} sm={6}>
                                                                <Maincontent>
                                                                    <Contentheader color={clickedBox(index,aIndex) ? '#E0E0E0' : '#565656'}>Test Type</Contentheader>
                                                                    <Contentbody color={clickedBox(index,aIndex) ? '#FFFFFF' : '#000000'}>{apptData?.isExpressSameDayTest ? 'Express' : apptData?.isRapidTest ? 'Rapid' : apptData?.isAntigen ? 'Antigen' : apptData?.isPanel ? 'Flu A&B/Covid-19' : 'Standard' }</Contentbody>
                                                                </Maincontent>
                                                            </Grid>
                                                            <Grid item xs={6} lg={3} md={4} sm={6}>
                                                                <Maincontent alignItems='flex-end'>
                                                                    <Contentheader text='right' color={clickedBox(index,aIndex) ? '#E0E0E0' : '#565656'}>Location</Contentheader>
                                                                    <Contentbody text='right' color={clickedBox(index,aIndex) ? '#FFFFFF' : '#000000'}>{apptData.location.address1 +' '+ apptData.location.address2}</Contentbody>
                                                                </Maincontent>
                                                            </Grid>
                                                            <Grid item xs={6} lg={2} md={4} sm={6}>
                                                                {checkAppointmentStatus(apptData,IsMinorAccount,minorEmail, clickedBox(index, aIndex))}
                                                            </Grid>
                                                            <Grid item xs={6} lg={1} md={4} sm={6}>
                                                                <Maincontent alignItems='flex-end'>
                                                                    {(hide.aIndex === aIndex && hide.index === index) ? 
                                                                    (<div style={{display:'flex', gap:3, alignItems:'center', color:`${clickedBox(index, aIndex) ? '#FAC21A' : '#004069'}`}}><p style={{display:'flex', alignItems:'center'}}> Hide Details &nbsp;<IoIosArrowUp size={35}/></p></div>) 
                                                                    : 
                                                                    (<div style={{display:'flex', gap:3, alignItems:'center', color:`${clickedBox(index, aIndex) ? '#FAC21A' : '#004069'}`}}><p style={{display:'flex', alignItems:'center'}}>Show Details &nbsp; <IoIosArrowDown size={35}/></p></div>)}
                                                                </Maincontent>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Subcontainer>
                                            </>
											{

												(hide.index == index && hide.aIndex == aIndex) &&
												(apptData.qbenchAcknowledgement.map((item: any, sindex: any) => {
													let minorcheck = true;
													if(IsMinorAccount)
													{
														let indexid = item.minorIndex;
														if(indexid!==-1)
														{
															minorcheck = toLower(item?.minors[indexid]?.email===undefined?'':item?.minors[indexid]?.email)===toLower(minorEmail?minorEmail:'')														
														}
														else
														{
															minorcheck = false;
														}
													}
													if (item.results !== "" && (apptData.orderById == 3 || apptData.orderById == 4) && minorcheck) {

														return (
															<div key={sindex} style={{border:'0.5px solid rgba(36, 61, 77, 0.14)', borderRadius:6,padding:6, marginBottom:15}}>
                                                                <div style={{display:'flex', height:'100%', width:'100%', alignItems:'center', justifyContent:'space-between', padding:'20px 10px'}}>
                                                                    <div style={{fontWeight:600, fontSize:24, color:'black'}}>
                                                                        Your COVID-19 Test Result
                                                                    </div>
                                                                    <Desktopbutton>
                                                                        <Button
                                                                            type="button"
                                                                            libraryType="primary"
                                                                            fullWidth={false}
                                                                            onClick={async () => {
                                                                                setSaving(true);
                                                                                try {
                                                                                    await downloadResultsPDF(item.sampleId);
                                                                                } catch (e) {
                                                                                    console.error(e);
                                                                                }
                                                                                setSaving(false);
                                                                            }}
                                                                        >
                                                                            {saving ? 'Downloading...' : 'Download Lab Report'}
                                                                        </Button>    
                                                                    </Desktopbutton> 
                                                                </div>
                                                                <Grid
                                                                    style={{padding:'0px 10px'}}
                                                                    container
                                                                    spacing={1}
                                                                    alignItems="flex-start"
                                                                    justify='flex-start'
                                                                >
                                                                    <Grid item xs={12} lg={6} md={6} sm={12}>
                                                                        <div style={{border:'0.5px solid #E0E0E0',display:'flex', flexDirection:'column',padding:5 }}>
                                                                            <p style={{fontWeight:600, fontSize:16, lineHeight:'134%', color:'#565656'}}>Test Result</p>
                                                                            {apptData?.isPanel ? apptData?.qbenchAcknowledgement.map((e:any)=>(
                                                                                    <div style={{display:'flex',flexDirection:'row', justifyContent:"space-between"}}>                      
                                                                                        { e.tests.map((item:any)=>(
                                                                                        <div>                                                                                                                                                             
                                                                                        <p style={{fontWeight:600, fontSize:16, lineHeight:'134%', color:'#565656'}}>{item?.name}</p>
                                                                                        <ResultValue value={normalizeResultName(item?.result)}>
                                                                                                {normalizeResultName(item?.result)}
                                                                                        </ResultValue>
                                                                                        </div>
                                                                                            )                                                   
                                                                                            )
                                                                                        }
                                                                                        </div>                                                                              
                                                                            ) 
                                                                            ) :
                                                                            (         
                                                                                    <>
                                                                                    <ResultValue value={normalizeResultName(item.results)}>
                                                                                            {normalizeResultName(item.results)}
                                                                                    </ResultValue>
                                                                                    </> 
                                                                            )}
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6} md={6} sm={12}>
                                                                        <div style={{border:'0.5px solid #E0E0E0',display:'flex', flexDirection:'column', padding:6}}>
                                                                            <p style={{fontWeight:600, fontSize:16, lineHeight:'134%', color:'#565656'}}>Patient’s Name</p>
                                                                            <p style={{fontWeight:600, fontSize:16, lineHeight:'134%', color:'#101010'}}>
                                                                            {apptData.qbenchAcknowledgement[sindex].firstName + ' ' +apptData.qbenchAcknowledgement[sindex].lastName}
                                                                            </p>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6} md={6} sm={12}>
                                                                        <div style={{border:'0.5px solid #E0E0E0',display:'flex', flexDirection:'column', padding:6}}>
                                                                            <p style={{fontWeight:600, fontSize:16, lineHeight:'134%', color:'#565656'}}>Collection Date</p>
                                                                            <p style={{fontWeight:600, fontSize:16, lineHeight:'134%', color:'#101010'}}>
                                                                                {item?.checkInTime? format(new Date(item?.checkInTime),'MM/dd/yyyy'):''}
                                                                            </p>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6} md={6} sm={12}>
                                                                        <div style={{border:'0.5px solid #E0E0E0',display:'flex', flexDirection:'column', padding:6}}>
                                                                            <p style={{fontWeight:600, fontSize:16, lineHeight:'134%', color:'#565656'}}>DOB</p>
                                                                            <p style={{fontWeight:600, fontSize:16, lineHeight:'134%', color:'#101010'}}>
                                                                                {apptData.qbenchAcknowledgement[sindex].birthDate !== undefined && (Object.keys(apptData.qbenchAcknowledgement[sindex].birthDate).length) > 0 ? apptData.qbenchAcknowledgement[sindex].birthDate : ''}
                                                                            </p>
                                                                        </div>
                                                                    </Grid>
                                                                    {apptData?.location && (<Grid item xs={12} lg={6} md={6} sm={12}>
                                                                        <div style={{border:'0.5px solid #E0E0E0',display:'flex', flexDirection:'column', padding:6}}>
                                                                            <p style={{fontWeight:600, fontSize:16, lineHeight:'134%', color:'#565656'}}>Collection Location</p>
                                                                            <p style={{fontWeight:600, fontSize:16, lineHeight:'134%', color:'#101010'}}>
                                                                            {apptData.location.address1 +' '+ apptData.location.address2}
                                                                            </p>
                                                                        </div>
                                                                    </Grid>
                                                                    )}
                                                                </Grid>
                                                                <p style={{padding:'15px 10px'}}>
																																		{item.results.toLowerCase()==='detected' 
																																		? 
																																				`A POSITIVE result for ${apptData.isPanel ? 'the Antigen Test' : 'this test'} means that SARS-CoV-2 RNA (the cause of COVID-19) was detected in the collection sample.`
																																		: 
																																				item.results.toLowerCase()==='not detected' 
																																						? 
																																								`A NEGATIVE result for ${apptData.isPanel ? 'the Antigen Test' : 'this test'} means that SARS-CoV-2 RNA (the cause of COVID-19) was not detected in the collected sample.`
																																						:   `An INDETERMINATE result for ${apptData.isPanel ? 'the Antigen Test' : 'this test'} means that SARS-CoV-2 RNA (the cause of COVID-19) was inconclusive in the collection sample.`}
																																</p>
                                                                <Mobilebutton style={{padding:'10px 0px 20px 10px'}}>
                                                                    <Button
                                                                        type="button"
                                                                        libraryType="primary"
                                                                        fullWidth={false}
                                                                        onClick={async () => {
                                                                            setSaving(true);
                                                                            try {
                                                                                await downloadResultsPDF(item.sampleId);
                                                                            } catch (e) {
                                                                                console.error(e);
                                                                            }
                                                                            setSaving(false);
                                                                        }}
                                                                    >
                                                                        {saving ? 'Downloading...' : 'Download Lab Report'}
                                                                    </Button>    
                                                                </Mobilebutton> 
                                                            </div>
														)
													} else if (item.results === ""  && minorcheck) {
														
														let data: any = []

														if ((apptData?.qbenchAcknowledgement.length > 0 && apptData?.minors?.length > 0) && apptData.orderById != 5) {
															apptData?.qbenchAcknowledgement?.map((item: any, i: any) => {
																if (item.results === "") {
																	data.push(
																		{ firstName: item.firstName, lastName: item.lastName }
																	)
																}

															})
														} else {
															data.push(
																{ firstName: apptData.firstName, lastName: apptData.lastName }
															)
														}

														return (

															<div
																key={sindex}
																style={{
																	display: IsMinorAccount?'block':(apptData.orderById == 2 || apptData.orderById == 4) && sindex == 0 ? "block" : apptData.orderById == 5 && sindex == 0 ? "block" : "none"
																}}
															>
																{data.length > 0 && item.results === "" ?
																	data.map((item: any, i: any) => (
																		<TestingInfoWrapper>
                                                                                <Grid
                                                                                    style={{padding:'0px 10px'}}
                                                                                    container
                                                                                    spacing={3}
                                                                                    alignItems="center"
                                                                                    justify='space-evenly'
                                                                                    >
                                                                                    <Grid item xs={6} lg={2} md={3} sm={6}>
                                                                                        <Maincontent>
                                                                                            <Contentheader>{apptData?.location?.name}</Contentheader>
                                                                                            <Contentbody>{apptData?.location?.address1+ ' ' + apptData?.location?.address2}</Contentbody>
                                                                                        </Maincontent>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} lg={2} md={3} sm={6}>
                                                                                        <Maincontent alignItems='flex-end'>
                                                                                            <Contentheader text='right'>Date</Contentheader>
                                                                                            <Contentbody text='right'>{apptData.date}</Contentbody>
                                                                                        </Maincontent>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} lg={2} md={3} sm={6}>
                                                                                        <Maincontent>
                                                                                            <Contentheader>Time</Contentheader>
                                                                                            <Contentbody>{apptData.cPartnerID === 'KEN001' || apptData?.isSolvHealth ? (apptData?.slot?.label ?? '') : apptData?.slot !== undefined && apptData?.slot !== null ? `${apptData?.isSchedularLite ? apptData?.slot?.label : slotsListPeriod( apptData.isRapidTest !== undefined && apptData.isRapidTest == true ? "rapid": "other", apptData?.slot)}` : "" }</Contentbody>
                                                                                        </Maincontent>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} lg={3} md={3} sm={6}>
                                                                                        <Maincontent alignItems='flex-end'>
                                                                                            <Contentheader text='right'> Appointment for</Contentheader>
                                                                                            <Contentbody text='right'>
                                                                                                <div>
                                                                                                    {IsMinorAccount ? '':(<>{item?.firstName} {item?.lastName}</>)}    
                                                                                                </div>
                                                                                                {(apptData && apptData?.minors?.length > 0)
                                                                                                    ? apptData?.minors?.filter((minor:any)=>!minor.isCanceled).map((items: any, i: any) => (
                                                                                                        <div key={i}>
                                                                                                            {
                                                                                                            IsMinorAccount ?
                                                                                                                toLower(minorEmail)===toLower(items.email)?
                                                                                                                    (<>{items.firstName} {items.lastName}</>)
                                                                                                                :
                                                                                                                    ''
                                                                                                            :
                                                                                                                ''
                                                                                                                // (<>{items.firstName} {items.lastName}</>)
                                                                                                            }
                                                                                                        </div>
                                                                                                    ))
                                                                                                : ''}
                                                                                            </Contentbody>
                                                                                        </Maincontent>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {/* <TestingInfoContainer>
                                                                                    <TestingInfo>
                                                                                        <PinIcon /> */}
                                                                                        {/* <strong>{apptData?.location?.name}</strong> */}
                                                                                        {/* <br /> */}
                                                                                        {/* {apptData?.location?.address1}{' '} */}
                                                                                        {/* {apptData?.location?.address2} */}
                                                                                    {/* </TestingInfo>
                                                                                </TestingInfoContainer> */}
                                                                                
                                                                                {/* <TestingInfoContainer>
                                                                                    <TestingInfoRow> */}
                                                                                        {/* <TestingInfo>
                                                                                            <CalendarIcon />
                                                                                            <strong>Date</strong>
                                                                                            <br />
                                                                                            {apptData?.date}
                                                                                        </TestingInfo> */}
                                                                                        {/* <TestingInfo>
                                                                                            <ClockIcon />
                                                                                            <strong>Time</strong>
                                                                                            <br /> */}
                                                                                            {/* {apptData?.slot && (apptData?.slot.label || apptData.isRapidTest != undefined ? apptData?.slot.period.label ? rapidPeriods[apptData?.slot.period].label  : periods[apptData?.slot.period].label)} */}
                                                                                            {/* {apptData.cPartnerID === 'KEN001' || apptData?.isSolvHealth ? (apptData?.slot?.label ?? '') : apptData?.slot !== undefined && apptData?.slot !== null ? `${apptData?.isSchedularLite ? apptData?.slot?.label : slotsListPeriod( apptData.isRapidTest !== undefined && apptData.isRapidTest == true ? "rapid": "other", apptData?.slot)}` : "" } */}
                                                                                        {/* </TestingInfo>
                                                                                    </TestingInfoRow>
                                                                                </TestingInfoContainer> */}

                                                                                {/* <TestingInfoContainer>
                                                                                    <TestingInfoRow>
                                                                                        <TestingInfo>
                                                                                            <PersonIcon />
                                                                                            <strong>Appointment for</strong>
                                                                                            <br />
                                                                                            {IsMinorAccount?'':(<>{item?.firstName} {item?.lastName}</>)}
                                                                                            {(apptData && apptData?.minors?.length > 0)
                                                                                                ? apptData?.minors?.filter((minor:any)=>!minor.isCanceled).map((items: any, i: any) => (
                                                                                                    <div key={i}>
                                                                                                        {IsMinorAccount?
                                                                                                            toLower(minorEmail)===toLower(items.email)?
                                                                                                                (<>{items.firstName} {items.lastName}</>)
                                                                                                            :
                                                                                                                ''
                                                                                                        :
                                                                                                            (<>{items.firstName} {items.lastName}</>)
                                                                                                        }
                                                                                                    </div>
                                                                                                ))
                                                                                            : ''}
                                                                                        </TestingInfo>
                                                                                    </TestingInfoRow>
                                                                                </TestingInfoContainer> */}
                                                                            </TestingInfoWrapper>
																	)) : ""
																}
																{
																	!apptData?.isSchedularLite && (IsMinorAccount || apptData?.isSolvHealth ? '' : apptData.orderById !== 4) &&
                                                                    <>
																	<Navigation>
																		{apptData?.date &&
																			isAfter(
																				parse(apptData?.date, config.dateFormat, new Date()),
																				new Date()
																			) && (
																				<Button
																					type="button"
																					libraryType="default"
																					onClick={() => {
																						let appointment = apptData
																						updateAppointment(appointment);
																						goToPage(ManagePage.Cancel)
																					}}
																				>
																					Cancel Appointment
																				</Button>
																			)}
																		<Button
																			type="button"
																			libraryType="primary"
																			onClick={async () => {
																				let appointment = apptData
																				updateAppointment(appointment);
																				// const data: any = await getSlotsByLocationId(apptData?.location?.qbenchCustomerId);
																				// updateRescheduleSlots(data);
																				goToPage(ManagePage.Reschedule);
																			}
																			}
																		>
																			Reschedule Appointment
                                  										</Button>
																	</Navigation>
                                                                    <NavigationSM>
                                                                        {apptData?.date &&
                                                                            isAfter(
                                                                                parse(apptData?.date, config.dateFormat, new Date()),
                                                                                new Date()
                                                                            ) && (
                                                                                <Button
                                                                                    type="button"
                                                                                    libraryType="default"
                                                                                    onClick={() => {
                                                                                        let appointment = apptData
                                                                                        updateAppointment(appointment);
                                                                                        goToPage(ManagePage.Cancel)
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                            )}
                                                                        <Button
                                                                            type="button"
                                                                            libraryType="primary"
                                                                            onClick={async () => {
                                                                                let appointment = apptData
                                                                                updateAppointment(appointment);
                                                                                // const data: any = await getSlotsByLocationId(apptData?.location?.qbenchCustomerId);
                                                                                // updateRescheduleSlots(data);
                                                                                goToPage(ManagePage.Reschedule);
                                                                            }
                                                                            }
                                                                        >
                                                                            Reschedule
                                                                        </Button>
                                                                    </NavigationSM>
                                                                    </>
																}

															</div>
														)
													}

												}))
											}
										</>
									)
								})
							}
						</div>
					)
				})}
			</Content>
		</Container >
        <Background/>
        </>
	);
};

export default TestResults;

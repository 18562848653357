import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../styles/colors';

const Container = styled.div`
  background-color: rgb(36, 61, 77);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0 0px 0px;
  font-size: 14px;
  text-decoration: underline;
  color: #000;

  & > * {
    display: block;
    margin: 0 15px;
  }

  button {
    background: none;
    border: 0;
    color: ${colors.darkBlack};
    cursor: pointer;
  }
`;

const Footer: React.FC = () => {
  return (
    <div>
      <Container>
        <a
          style={{ color: 'white' }}
          href="https://www.worksitelabs.com/privacy"
          target="_blank"
          rel="noreferrer noopener"
        >
          Privacy Policy
        </a>
        { (
            <button
              style={{ color: 'white', textDecoration: 'underline' }}
              type="button"
              onClick={() => (window.location.pathname = '/manage')}
            >
              Manage Appointment
            </button>
          )}
      </Container>
    </div>
  );
};

export default Footer;

import React, {
  useCallback,
  useState,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import { User } from 'firebase/app';
import { format, parse, compareAsc, compareDesc } from 'date-fns';

import {
  AdminState,
  AdminDispatch,
  AppointmentListItem,
  Comparables,
  AppointmentsListParams,
  ConsolidationParams,
  Appointment,
} from './models';
import { SortByDirection, FieldType } from './dictionaries';
import { firebase } from '../../../../firebase';
import { config } from '../../../../config';
// import { getSlotsConfigurations } from "../../api";

export const AdminContext = createContext<AdminState>({
  params: {
    firstName: '',
    lastName: '',
    confirmationId: '',
    phone: '',
    birthDate: '',
    email: '',
    date: format(new Date(), config.dateFormat),
    locationQbenchId: null,
  },
  appointments: null,
  Editappointments:null,
  sortBy: null,
  sortByDirection: SortByDirection.Ascending,
  user: null,
  configured: false,
  adminSlots:[]
});

export const AdminDispatchContext = createContext<AdminDispatch>({
  updateParams() {},
  setAppointments() {},
  sortTable() {},
  setUser() {},
  updateAppointmentInStore() {},
  reportParams() {},
  setEditAppointment(){},
  updateAdminSlots(){}
  // setReportFilter() {}
});

export const AdminProvider = ({ children }: { children: React.ReactNode }) => {
  const [params, setParams] = useState<AppointmentsListParams>({
    firstName: '',
    lastName: '',
    confirmationId: '',
    phone: '',
    birthDate: '',
    email: '',
    date: format(new Date(), config.dateFormat),
    locationQbenchId: null,
  });

  const [reportparams, setreportParams] = useState<ConsolidationParams>({
    appttime: '',
    results: '',
    location: '',
    fromdate: '',
    todate: ''
  });

  const [appointments, setAppointments] = useState<
    AppointmentListItem[] | null
  >(null);

  const [Editappointments, setEditAppointment] = useState<
  Appointment[] | null
>(null);

  
  const [sortBy, setSortBy] = useState<keyof AppointmentListItem | null>(null);
  const [sortByDirection, setSortByDirection] = useState<SortByDirection>(
    SortByDirection.Ascending
  );
  const [user, setUser] = useState<User | null>(null);
  const [configured, setConfigured] = useState(false);
  const [adminSlots, setSlots] = useState([]);
  // const [appointments, setReportFilter] = useState<ConsolidationParams[] | null>(null);

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser) => {
        if (firebaseUser) {
          setUser(firebaseUser);
        }

        setConfigured(true);
      });

      // getSlotsConfigurations().then((result)=>{
      //   console.log("admin", result);
      //   setSlots(result.data);
      // })

    return () => unsubscribe();
  }, [setUser]);

  const updateParams = useCallback(
    (update: AppointmentsListParams) => {
      setParams((currentParams) => ({
        ...(currentParams || {}),
        ...update,
      }));
    },
    [setParams]
  );

  const reportParams = useCallback(
    (update: ConsolidationParams) => {
      setreportParams((currentParams) => ({
        ...(currentParams || {}),
        ...update,
      }));
    },
    [setreportParams]
  );

  const sortTable = (columnName: keyof AppointmentListItem) => {
    let direction: SortByDirection = SortByDirection.Ascending;

    if (sortBy === columnName) {
      direction =
        sortByDirection === SortByDirection.Ascending
          ? SortByDirection.Descending
          : SortByDirection.Ascending;
    }

    setSortBy(columnName);
    setSortByDirection(direction);
  };

  const sortAppointments = (
    appointments: AppointmentListItem[] | null,
    sortBy: keyof AppointmentListItem | null,
    sortByDirection: SortByDirection
  ) => {
    if (sortBy !== null && appointments !== null && appointments.length > 0) {
      const compare = comparables[sortBy] || { type: FieldType.Text };

      appointments?.sort((a, b) => {
        if (compare.type !== FieldType.Date) {
          let aval: string = String(a[sortBy]).toUpperCase();
          let bval: string = String(b[sortBy]).toUpperCase();
          if (aval < bval) {
            return sortByDirection === SortByDirection.Ascending ? -1 : 1;
          }
          if (aval > bval) {
            return sortByDirection === SortByDirection.Ascending ? 1 : -1;
          }
          return 0;
        } else {
          const aDate = parse(
            a[sortBy] as string,
            compare?.data?.dateFormat,
            new Date()
          );
          const bDate = parse(
            b[sortBy] as string,
            compare?.data?.dateFormat,
            new Date()
          );
          return sortByDirection === SortByDirection.Ascending
            ? compareAsc(aDate, bDate)
            : compareDesc(aDate, bDate);
        }
      });
    }
    return appointments;
  };

  const updateAppointmentInStore = (
    firebaseId: string,
    minorIndex: number | null,
    update: Partial<AppointmentListItem>
  ) => {
    setAppointments((appts) => {
      if (appts === null) {
        return null;
      }

      const index = appts.findIndex(
        (appt) => appt.id === firebaseId && appt.minorIndex === minorIndex
      );
      appts[index] = {
        ...appts[index],
        ...update,
      };

      return appts;
    });
  };

  const updateAdminSlots = useCallback(
    (update: any) => {
      setSlots(update)
    },
    [setSlots]
  );	

  const store = useMemo(
    () => ({
      params,
      appointments: sortAppointments(appointments, sortBy, sortByDirection),
      sortBy,
      sortByDirection,
      user,
      configured,
      Editappointments,
      adminSlots
    }),
    [params, appointments, sortBy, sortByDirection, user, configured,Editappointments, adminSlots]
  );

  const dispatch = useMemo(
    () => ({
      updateParams,
      setAppointments,
      sortTable,
      setUser,
      updateAppointmentInStore,
      reportParams,
      setEditAppointment,
      updateAdminSlots
    }),
    [sortBy, sortByDirection] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <AdminContext.Provider value={store}>
      <AdminDispatchContext.Provider value={dispatch}>
        {children}
      </AdminDispatchContext.Provider>
    </AdminContext.Provider>
  );
};

export const useAdminState = () => {
  const context = React.useContext(AdminContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useAdminState` hook must be used within a `Provider` component'
    );
  }

  return context;
};

export const useAdminDispatch = () => {
  const context = React.useContext(AdminDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useAdminDispatch` hook must be used within a `Provider` component'
    );
  }

  return context;
};

const comparables: Comparables = {
  birthDate: {
    type: FieldType.Date,
    data: {
      dateFormat: config.dateFormat,
    },
  },
  departureDateAndTime: {
    type: FieldType.Date,
    data: {
      dateFormat: config.dateTimeFormat,
    },
  },
};

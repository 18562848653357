import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { useStepsState, useStepsDispatch } from '../../shared/providers/schedule/provider';
import { Button } from './styled';
import { colors } from '../../../styles/colors';
import { Breakpoints } from '../../../dictionaries';

import Modal from './Modal';
import LocationButtonGroup from './form/LocationButtonGroup';
import { cloneDeep } from 'lodash';
import AlertModal from "./AlertModal";

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
`;

const Title = styled.h2`
  color: ${colors.renderBlue};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

interface Props {
  openModal: () => void;
}

const ChangeLocationModal: React.FC<Props> = ({ openModal }) => {
  const {
    form: { location, slot, isExpressSameDayTest },
    showChangeLocationModal,
  } = useStepsState();
  const { toggleChangeLocationModal, updateFormValues } = useStepsDispatch();
  const closeModal = () => toggleChangeLocationModal(false);
  const rollbackLocation = useMemo(() => cloneDeep(location), [showChangeLocationModal]);
  const rollbackSlot = useMemo(() => cloneDeep(slot), [showChangeLocationModal]);
  const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(isExpressSameDayTest), [showChangeLocationModal]);

  return (
    <Modal open={showChangeLocationModal} onClose={closeModal}>
      <Formik
        initialValues={{ location }}
        onSubmit={() => {
          updateFormValues({ update: location });
          updateFormValues({ update: slot });
          if (slot === undefined) {
            openModal();
          }
          // if (location?.hasVipSlots) {
          //   updateFormValues({ isExpressSameDayTest: true });
          // }
          // else {
          //   updateFormValues({ isExpressSameDayTest: false });
          // }
          closeModal();
        }}
      >
        {() => (
          <Form>
            <Content>
              <Title>Change location</Title>
              <LocationButtonGroup confirmAndPay={true} />
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => {
                    updateFormValues({ location: rollbackLocation, slot: rollbackSlot, isExpressSameDayTest: rollbackisExpressSameDayTest });
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit">
                  Change
                </Button>
              </ButtonsContainer>
            </Content>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeLocationModal;
